import React, { useState, useEffect } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { DataTable, Dialog, Button, Portal } from 'react-native-paper'
import _ from 'underscore'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useRoute, useNavigation } from '@react-navigation/native'

const AlertDetailView = (props) => {
  const [page, setPage] = useState(0)
  const [alerts, setAlerts] = useState([])
  const [roomName, setRoomName] = useState('')
  const [sections, setSections] = useState({})
  const navigation = useNavigation()
  const route = useRoute()
  const { type, roomId } = route.params || {}
  const indexedFarms = useSelector(state => state.indexedFarms)
  const locateRoom = (id) => {
    return new Promise(async (resolve) => {
      let result
      Object.values(indexedFarms).forEach((farm) => {
        Object.values(farm.buildings).forEach((building) => {
          building.rooms.forEach((room) => {
            if (room.id === id) {
              result = room
            }
          })
        })
      })
      resolve(result)
    })
  }
  useEffect(() => {
    if (roomId) {
      locateRoom(roomId)
        .then(room => {
          let sections = {}
          room.rows.forEach(row => {
            row.levels.forEach(level => {
              level.squares.forEach(square => {
                sections[square.id] = square
              })
            })
          })
          let alerts = room.alerts.filter((alert) => alert.measureType.measureTypeName === type)
          alerts.sort((a, b) => moment(b.record.recordedAt).diff(a.record.recordedAt))
          setPage(0)
          setSections(sections)
          setAlerts(alerts)
          setRoomName(room.name)
        })
    }
  }, [roomId, type])
  let pageSize = 5
  let start = page * pageSize
  let end = (page + 1) * pageSize
  end = end > alerts.length ? alerts.length : end
  const process = (alert) => {
    let valueTypes = ['intCount', 'booleanValue', 'doubleMeasure']
    for (let i = 0; i < valueTypes.length; i++) {
      let value = alert[valueTypes[i]]
      if (typeof value === 'boolean') {
        return value ? 'Presence' : 'Absence'
      }
      if (!isNaN(String(value))) {
        return value
      }
    }
    return 'N/A'
  }
  const onDismiss = () => {
    navigation.setParams({ type: undefined, roomId: undefined })
  }
  return (
    <View>
      <Portal>
        <Dialog visible={!!type} onDismiss={onDismiss}>
          <Dialog.ScrollArea style={{paddingHorizontal: 0}}>
            <ScrollView>
              <Dialog.Title>{`${String(type).split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')} Alerts`}</Dialog.Title>
              <Dialog.Content style={{ paddingHorizontal: 0, paddingBottom: 0 }}>
                <DataTable style={styles.table}>
                  <DataTable.Header style={{ paddingHorizontal: 24 }}>
                    <DataTable.Title style={{ flex: 0.75 }}>Section</DataTable.Title>
                    <DataTable.Title>Value</DataTable.Title>
                    <DataTable.Title>Time</DataTable.Title>
                    <DataTable.Title style={{ flex: 0.75 }} numeric>Priority</DataTable.Title>
                  </DataTable.Header>
                  {
                    alerts.slice(start, end).map((alert) => (
                      <DataTable.Row style={{ paddingHorizontal: 24 }} key={alert.id}>
                        <DataTable.Cell style={{ flex: 0.75 }}>{(sections[alert.squareId] || { name: 'N/A' }).name}</DataTable.Cell>
                        <DataTable.Cell>{process(alert.record)}</DataTable.Cell>
                        <DataTable.Cell>{moment(alert.record.recordedAt).format('H:mm M/D')}</DataTable.Cell>
                        <DataTable.Cell style={{ flex: 0.75 }} numeric>{(alert.color === 'RED' ? 'Red' : 'Yellow')}</DataTable.Cell>
                      </DataTable.Row>
                    ))
                  }
                  {
                    alerts.length > pageSize &&
                      <DataTable.Pagination
                        page={page}
                        numberOfPages={Math.ceil(alerts.length / pageSize)}
                        onPageChange={setPage}
                        label={`${start + 1}-${end} of ${alerts.length}`}
                      />
                  }
                </DataTable>
              </Dialog.Content>
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions>
            <Button onPress={onDismiss}>Cancel</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  )
}

const styles = StyleSheet.create({
  table: {
    width: '100%'
  }
})

export default AlertDetailView
