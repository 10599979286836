const fonts = {
  'Roboto Regular': require('./Roboto/Roboto-Regular.ttf'),
  'Roboto Italic': require('./Roboto/Roboto-Italic.ttf'),
  'Roboto Bold': require('./Roboto/Roboto-Bold.ttf'),
  'Roboto Black': require('./Roboto/Roboto-Black.ttf'),
  'Roboto Medium': require('./Roboto/Roboto-Medium.ttf'),
  'Roboto Light': require('./Roboto/Roboto-Light.ttf'),
  'Roboto Thin': require('./Roboto/Roboto-Thin.ttf')
}

export default fonts
