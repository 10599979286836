import React, { useEffect, useState, useCallback } from 'react'
import { View, StyleSheet, ScrollView, Dimensions, ActivityIndicator, FlatList, Text } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { SectionCard, SearchField, Dropdown, InfoCard, AddModal, Header } from './components'
import _, { isEmpty, object, sortBy } from 'underscore'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { FAB } from 'react-native-paper'

const SectionsScreen = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [sections, setSections] = useState([])
  const [rowLetter, setRowLetter] = useState('')
  const [levelNum, setLevelNum] = useState('')
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [room, setRoom] = useState({})
  const [rows, setRows] = useState({})
  const [grid, setGrid] = useState([])
  const { roomId, buildingId, farmId, recordType } = props.route.params
  const { indexedFarms } = props
  const loadAsync = async () => {
    setQuery('')
    setRowLetter('')
    setLevelNum('')
    let room = indexedFarms[farmId]['buildings'][buildingId]['rooms'].filter(r => r.id === roomId)[0]
    let rowsIndexed = {}
    let sections = []
    let grid = []
    // Index Sections into Letter-Num-Num form
    room.rows.forEach(row => {
      row.letter = row.letter.toUpperCase()
      if (!rowsIndexed[row.letter]) rowsIndexed[row.letter] = {}
      row.levels.forEach(level => {
        if (!rowsIndexed[row.letter][level.num]) rowsIndexed[row.letter][level.num] = level.squares
      })
    })
    // Unpack indexedRows into Sections Array
    for (const [letter, row] of (Object.entries(rowsIndexed)).sort()) {
      let flatRow = []
      for (const [num, level] of Object.entries(row).sort()) {
        flatRow.push(...level)
      }
      // Sort Flat Row before putting in grid
      let sortedRow = _.sortBy(flatRow, function (section) {return section.name})
      if (sortedRow.length > 0) grid.push(sortedRow)
    }

    setGrid(grid)
    setSections(sections)
    setIsLoaded(true)
    setRoom(room)
    setRows(rowsIndexed)
  }
  useEffect(() => {
    if (!isLoaded) {
      loadAsync()
    }
  }, [isLoaded])
  useFocusEffect(
    useCallback(() => {
      if (isLoaded) {
        loadAsync()
      }
    }, [roomId])
  )
  
  useEffect(() => {
    let results = grid
    // Filter Sections by Query
    if (!isEmpty(query)) {
      let filteredGrid = []
      Object(results).forEach(row => {
        let filteredRow = row.filter(section => {
          return section.name.toLowerCase().includes(query.toLowerCase())
        })
        if (filteredRow.length > 0) filteredGrid.push(filteredRow)
      })
      results = filteredGrid
    }

    // Filter by Dropdowns
    if (rowLetter && levelNum) { // Row and Level Dropdown Set
      let filteredGrid = results.filter(row => {
        return rowLetter === row[0].name.charAt(0)
      })
      setResults( [
        filteredGrid[0].filter(section => {
          let [row, level, sectionNum] = section.name.split('-')
          return levelNum == level
        })
      ])
    }
    else if (rowLetter) { // Only Row Dropdown Set
      setResults( results.filter(row => {
        return rowLetter === row[0].name.charAt(0)
      }))
    }
    else if (levelNum) {
      let filteredGrid = []
      results.forEach(row => {
          filteredGrid.push(row.filter(section => {
            let [row, level, sectionNum] = section.name.split('-')
            return levelNum == level
          }))
      })
      setResults(filteredGrid)
    }
    else {
      setResults(results)
    }
  }, [query, rowLetter, levelNum])
  const renderItem = ({ item }) => (
    <SectionCard 
      section={item}
      recordType={recordType}
    />
  )
  const navigate = () => {
    console.log('Loading Row')
    props.navigation.navigate('SectionsAlt', { roomId, buildingId, farmId, add: recordType})
  }
  let listProps = {
    renderItem: renderItem,
    keyExtractor: item => item.id,
    style: styles.cardScroll
  }
  if (grid.length === 0 && !isLoaded) {
    listProps.ListHeaderComponent = <ActivityIndicator size="small" color="#222e3d" />
  } else if (grid.length === 0 && results.length === 0 && isLoaded) {
    listProps.ListHeaderComponent = <InfoCard iconName="alert-outline" text="Looks like there are no sections in this room." buttonText="Go Back" onButtonPress={() => props.navigation.goBack()} />
  }
  return (
    <SafeAreaView edges={['right', 'bottom', 'left']} style={styles.container}>
      <View style={styles.viewPadding}/>
      <SearchField handleTextChange={setQuery} placeholder={`Search ${room.name}'s Sections`} />
      <View style={{ flexDirection: 'row', marginHorizontal: 24 }}>
        <Dropdown
          placeholder={'Row...'}
          pic="menu"
          value={rowLetter}
          style={[styles.filterDropdown, { marginRight: 24 }]} 
          onChange={setRowLetter}
          items={Object.keys(rows).sort().map(rowLetter => {
            return { label: 'Row ' + rowLetter, value: rowLetter }
          })}
        />
        <Dropdown
          placeholder={'Level...'}
          pic="checkbox-multiple-blank-outline"
          value={levelNum}
          style={styles.filterDropdown}
          onChange={setLevelNum}
          items={
            _.uniq(Object.values(rows).sort().map(row => {
              return Object.keys(row).map(levelNum => {
                return { label: 'Level ' + levelNum, value: Number(levelNum) }
              })
            }).flat(), 'value')
        }
        />
      </View>
      <ScrollView>
      {
        results.length > 0 ? 
        [
          results && results.map((row, index) => (
            <View id={row[0].name.charAt(0)} key={index}>
              <Text style={styles.rowTitleText}>{'Row ' + row[0].name.charAt(0)}</Text>
              <FlatList
                horizontal
                data={row}
                id={row[0].name.charAt(0)}
                key={row[0].name.charAt(0)}
                {...listProps}
              />
            </View>
          ))
        ] : results.length === 0 && query.length > 0 || grid.length == 0 ?
          <InfoCard text="Your search did not produce any results." iconName="alert-outline" style={{marginHorizontal: 24, marginVertical: 12}} /> :
          [
            grid && grid.map((row, index) => (
              <View id={row[0].name.charAt(0)} key={index}>
                <Text style={styles.rowTitleText}>{'Row ' + row[0].name.charAt(0)}</Text>
                <FlatList
                  horizontal
                  data={row}
                  id={row[0].name.charAt(0)}
                  key={row[0].name.charAt(0)}
                  {...listProps}
                />
              </View>
            ))
          ]
      }
      </ScrollView>
      <AddModal />
      <FAB
        style={styles.fab}
        label='Row View'
        icon="compare-horizontal"
        onPress={navigate}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    flex: 1
  },
  viewPadding: {
    paddingTop: 12
  },
  cardScroll: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    flexGrow: 1
  },
  farmOptions: {
    justifyContent: 'flex-end'
  },
  farmActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
    marginHorizontal: 12,
  },
  actionButtonText1: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  rowTitleText: {
    fontFamily: 'Roboto Medium',
    fontSize: 20,
    marginLeft: 25,
    color: '#43425D'
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#43425D',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  actionButtonText2: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: 'white'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  },
  filterDropdown: {
    width: (Dimensions.get('window').width - 72) / 2
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionsScreen)
