import React, { useState , useEffect} from 'react'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { View, Text, StyleSheet, TouchableWithoutFeedback, Animated, Easing } from 'react-native'
import _ from 'underscore'
import { DataTable, ActivityIndicator, TouchableRipple } from 'react-native-paper'
import AsyncStorage from '../AsyncStorage'
import { connect, ConnectedProps } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import { useNavigation } from '@react-navigation/native'

const connector = connect(mapStateToProps, mapDispatchToProps)
interface AlertDropdownOwnProps {
  room: any
}
type AlertDropdownProps = ConnectedProps<typeof connector> & AlertDropdownOwnPropsp

const AlertDropdown = (props: AlertDropdownProps) => {
  const navigation = useNavigation()
  const [groupedAlerts, setGroupedAlerts] = useState({})
  const [priority, setPriority] = useState('')
  const [alerts, setAlerts] = useState(props.alerts)
  const [isLoaded, setIsLoaded] = useState(false)
  const [dropAnim] = useState(new Animated.Value(0))
  const { room, isConnected, dbUrl, accessToken, indexedFarms, saves } = props
  const save = async (alerts) => {
    indexedFarms[room.farmId]['buildings'][room.buildingId]['rooms'].forEach((r) => {
      if (r.id === room.id) {
        r.alerts = alerts
      }
    })
    let farms = []
    Object.values(indexedFarms).forEach((farm: any) => {
      if (farm.isInit) {
        farms.push(
          {
            ...farm,
            buildings: Object.values(farm.buildings),
            measureTypes: Object.values(farm.measureTypes),
            cropInputTypes: Object.values(farm.cropInputTypes),
            cropOutputTypes: Object.values(farm.cropOutputTypes),
            cropInputUnitOfMeasures: Object.values(farm.cropInputUnitOfMeasures),
            crops: farm.crops.map((crop) => {
              return { ...crop, cropCycles: Object.values(crop.cropCycles) }
            })
          }
        )
      }
    })
    props.setIndexedFarms(indexedFarms)
    await AsyncStorage.multiSet([['@mushrooms_farms', JSON.stringify(farms)], ['@mushrooms_indexedFarms', JSON.stringify(indexedFarms)]])
  }
  const loadSync = () => {
    if (isConnected) {
      let options = {
        headers: {
          'X-Access-Token': accessToken,
          'Content-Type': 'application/json'
        }
      }
      fetch(`${dbUrl}/api/alerts/farm=${room.farmId}/room=${room.id}`, options)
        .then(res => res.json())
        .then(data => {
          if (Array.isArray(data)) {
            let alerts = data.filter(alert => alert.record)
            setGroupedAlerts(_.groupBy(alerts, (alert) => { return alert.measureType.measureTypeName }))
            setAlerts(alerts)
            setPriority(
              alerts.filter(alert => alert.color === 'RED').length > 0 ?
                'high' : 'low'
            )
            setIsLoaded(true)
            save(alerts)
          }
        })
    } else {
      setGroupedAlerts(_.groupBy(alerts, (alert) => { return alert.measureType.measureTypeName }))
      setPriority(
        alerts.filter(alert => alert.color === 'RED').length > 0 ?
          'high' : 'low'
      )
      setIsLoaded(true)
    }
  }
  useEffect(() => {
    if (!isLoaded) {
      loadSync()
    }
  }, [isLoaded])
  useEffect(() => {
    if (isLoaded && isConnected) {
      loadSync()
    }
  }, [saves, isConnected])
  const AlertCountIcon = () => (
    <View style={[
      styles.badge,
      !priority ? { width: 12, height: 12 } :
        priority === 'high' ?
          { backgroundColor: 'red' } :
          { backgroundColor: 'yellow' }
    ]}>
      {
        priority ? 
          <Text style={[
            styles.badgeText,
            priority === 'high' ?
              { color: 'white' } :
              { color: 'black' }
          ]}>{alerts.length}</Text> :
          <ActivityIndicator animating={true} color={'#43425D'} />
      }
    </View>
  )
  const animate = (toValue = 1, easing = Easing.bezier(0.39, 0.575, 0.565, 1)) => {
    if (dropAnim._value === 1) {
      toValue = 0, easing = Easing.in
    }
    Animated.timing(
      dropAnim,
      {
        toValue,
        duration: 200,
        easing,
        useNativeDriver: false
      }
    ).start()
  }
  const onRowPress = (measureTypeName) => {
    navigation.setParams({ type: measureTypeName, roomId: room.id })
  }
  return (
    <View>
      <TouchableWithoutFeedback onPress={() => animate()}>
        <View style={styles.dropdownButton}>
          <View style={styles.alertInfo}>
            <View style={styles.alertIcon}>
              <Ionicons name="md-notifications" size={24} />
              {
                alerts.length > 0 &&
                  <AlertCountIcon />
              }
            </View>
            <Text style={[styles.alertText, { marginLeft: 24 }]}>Alerts</Text>
          </View>
          <Animated.View style={{
            transform: [{
              rotate: dropAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ['0deg', '180deg']
              })
            }]
          }}>
            <MaterialCommunityIcons name="chevron-down" size={20} color="#888888"/>
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={{
        overflow: 'hidden',
        height: dropAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [0, (Object.keys(groupedAlerts).length + 1) * 48]
        })
      }}>
        <DataTable>
          <DataTable.Header style={{ paddingHorizontal: 12 }}>
            {
              alerts.length > 0 ? <>
                <DataTable.Title style={{ flex: 2 }}>Measure Type</DataTable.Title>
                <DataTable.Title numeric>Total</DataTable.Title>
                <DataTable.Title numeric>Red</DataTable.Title>
                <DataTable.Title numeric>Yellow</DataTable.Title>
              </> : <DataTable.Title>No Alerts</DataTable.Title>
            }
          </DataTable.Header>
          {
            Object.keys(groupedAlerts).map((measureTypeName) => (
              <TouchableRipple onLongPress={() => onRowPress(measureTypeName)} onPress={() => onRowPress(measureTypeName)} key={`${measureTypeName}-${room.id}`}>
                <View>
                  <DataTable.Row style={{ paddingHorizontal: 12 }}>
                    <DataTable.Cell style={{ flex: 2 }}>
                      {measureTypeName}
                    </DataTable.Cell>
                    <DataTable.Cell numeric>
                      {groupedAlerts[measureTypeName].length}
                    </DataTable.Cell>
                    <DataTable.Cell numeric>
                      {groupedAlerts[measureTypeName].filter(alert => { return alert.color === 'RED'}).length}
                    </DataTable.Cell>
                    <DataTable.Cell numeric>
                      {groupedAlerts[measureTypeName].filter(alert => { return alert.color === 'YELLOW'}).length}
                    </DataTable.Cell>
                  </DataTable.Row>
                </View>
              </TouchableRipple>
            ))
          }
        </DataTable>
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  dropdownButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  alertInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertText: {
    fontFamily: 'Roboto Regular',
    fontSize: 14
  },
  alertItem: {
    height: 50.3,
    paddingHorizontal: 12
  },
  alertIcon: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  badge: {
    position: 'absolute',
    top: -9,
    right: -9,
    width: 18,
    height: 18,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center'
  },
  badgeText: {
    fontFamily: 'Roboto Medium',
    fontSize: 11,
    textAlign: 'center'
  },
  alertInfoText: {
    fontSize: 14,
    color: 'black',
    fontFamily: 'Roboto Black'
  },
  label: {
    backgroundColor: 'red',
    borderRadius: 3,
    marginRight: 12,
    paddingVertical: 3,
    paddingHorizontal: 6,
    width: 42
  },
  labelText: {
    fontFamily: 'Roboto Bold',
    fontSize: 10,
    color: 'white',
    letterSpacing: 1.5,
    textAlign: 'center'
  },
  alertSectionWrapper: {
    height: 50.3,
    top: 0,
    left: 0,
    position: 'absolute',
    justifyContent: 'center',
    paddingHorizontal: 12
  },
  alertSectionInfo: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#d5d5d5',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alertSectionText: {
    fontFamily: 'Roboto Medium',
    fontSize: 8,
    textAlign: 'center'
  }
})

// export default function(props) {
//   const navigation = useNavigation()
//   return <AlertDropdown navigation={navigation} {...props} />
// }

export default connect(mapStateToProps, mapDispatchToProps)(AlertDropdown)