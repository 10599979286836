import React, { useState, useEffect, useContext } from 'react'
import { View, TextInput, ScrollView, StyleSheet, Platform, Dimensions, Animated, Pressable, GestureResponderEvent } from 'react-native'
import { Dropdown, TagEntry } from './'
import { ActivityIndicator, Button, IconButton, Portal, Dialog, Snackbar } from 'react-native-paper'
import DateTimePicker from '@react-native-community/datetimepicker'
import moment from 'moment'
import { useKeyboard } from '@react-native-community/hooks'
import { useNavigation, useRoute } from '@react-navigation/native'
import AsyncStorage from '../AsyncStorage'
import uuid from 'uuid/v4'
import { connect, ConnectedProps } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import { SectionsScreenProps as NavigationProps } from '../App'
import AuthContext from '../AuthContext'

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddFormProps = ConnectedProps<typeof connector>

const AddModal = (props: AddFormProps) => {
  const [tags, setTags] = useState([])
  const [value, setValue] = useState('')
  const [pickerValue, setPickerValue] = useState('')
  const [unit, setUnit] = useState('')
  const [date, setDate] = useState(moment().format('MM/DD/YY'))
  const [time, setTime] = useState(moment().format('HH:mm'))
  const keyboard = useKeyboard()
  const navigation = useNavigation()
  const route = useRoute<NavigationProps['route']>()
  const { add, roomId, sectionId, MIOTypeId } = route.params || {}
  const [farmId, setFarmId] = useState(route.params?.farmId)
  const { name } = route
  const { indexedFarms } = props
  const authContext = useContext(AuthContext)
  useEffect(() => {
    if (add) {
      setDate(moment().format('MM/DD/YY'))
      setTime(moment().format('HH:mm'))
      setPickerValue('')
      setValue('')
      setUnit('')
      setTags([])
      persistMIOType()
    }
  }, [add])
  const onDismiss = () => {
    navigation.setParams({ add: undefined, roomId: name === 'Sections' ? roomId : undefined, sectionId: undefined })
  }
  const locateRoom = (id) => {
    return new Promise(async (resolve) => {
      let result
      Object.values(indexedFarms).forEach((farm) => {
        Object.values(farm.buildings).forEach((building) => {
          building.rooms.forEach((room) => {
            if (room.id === id) {
              result = room
            }
          })
        })
      })
      resolve(result)
    })
  }
  useEffect(() => {
    let { farmId } = route.params || {}
    if (!farmId) {
      if (roomId) {
        locateRoom(roomId)
          .then(room => setFarmId(room.farmId))
      }
    } else {
      setFarmId(farmId)
    }
  }, [roomId])

  /**
   * Grab the selected measure type from AsyncStorage and update the ui for it.
   *
   * MIO -> Measure, crop Input, crop Output
   */
  const persistMIOType = async () => {
    const clearSelectedType = async () => {
      await AsyncStorage.removeItem('@mushrooms_selectedMIOTypeId')
    }
    const typeId = MIOTypeId ?? undefined
    if(typeId !== undefined){
      let { measureTypes, cropInputTypes, cropOutputTypes } = indexedFarms[farmId]
      switch (add) {
        case 'measure':
          if(measureTypes[typeId])
            onPickerChange(typeId)
          else 
            clearSelectedType()
          break
        case 'crop input':
          if(cropInputTypes[typeId])
            onPickerChange(typeId)
          else 
            clearSelectedType()
          break
        case 'crop output':
          if(cropOutputTypes[typeId])
            onPickerChange(typeId)
          else 
            clearSelectedType()
          break
      }
    }
  }
  const onPickerChange = async (typeId) => {
    let { measureTypes, cropInputTypes, cropOutputTypes, cropInputUnitOfMeasures } = indexedFarms[farmId]
    if (typeId) {
      switch (add) {
        case 'measure':
          setUnit(measureTypes[typeId]['unitOfMeasure'])
          break
        case 'crop input':
          setUnit(cropInputUnitOfMeasures[cropInputTypes[typeId]['unitOfMeasureId']]['unitOfMeasure'])
          break
        case 'crop output':
          setUnit(cropInputUnitOfMeasures[cropOutputTypes[typeId]['unitOfMeasureId']]['unitOfMeasure'])
      }
    } else {
      setUnit('')
    }
    setPickerValue(typeId)
    setValue('')
    await AsyncStorage.setItem('@mushrooms_selectedMIOTypeId', typeId)
  }
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [invalidTimeAnim] = useState(new Animated.Value(0))
  const [invalidDateAnim] = useState(new Animated.Value(0))
  const [timePressed, setTimePressed] = useState(false)
  const [datePressed, setDatePressed] = useState(false)
  const isValid = (dateOrTime) => {
    let datetime
    let comparator = []
    let anim
    switch (dateOrTime) {
      case 'date':
        datetime = date
        comparator = ['MM/DD/YY', 'M/D/YY', 'MM/DD/YYYY', 'M/D/YYYY']
        anim = invalidDateAnim
        break
      case 'time':
        datetime = time
        comparator = ['HH:mm','h:mm','h:mm','hmm','HHmm']
        anim = invalidTimeAnim
    }
    let toValue, isValid
    if (moment(datetime, comparator, true).isValid()) {
      toValue = 0, isValid = true
    } else {
      toValue = 1, isValid = false
    }
    Animated.timing(anim, { toValue, duration: 200 }).start()
    return isValid
  }
  const [lastItem, setLastItem] = useState({})
  const onSubmit = async () => {
    let body = { roomId, id: uuid() }
    if (sectionId) body.squareId = sectionId
    switch (add) {
      case 'measure':
        body.measureTypeId = pickerValue
        break
      case 'crop input':
        body.cropInputTypeId = pickerValue
        break
      case 'crop output':
        body.cropOutputTypeId = pickerValue
    }
    switch (unit) {
      case 'Count':
        body.intCount = parseInt(value)
        break
      case 'Presence/Absence':
        body.booleanValue = value === '' || value
        break
      default:
        body.doubleMeasure = parseFloat(value)
    }
    if (isValid('date') && isValid('time')) {
      body.recordedAt = moment(date + ' ' + time).format()
    } else {
      setSnackbarMessage('Cannot save with invalid date or time.')
      setSnackbarVisible(true)
      return
    }
    let mappedTags = {}
    for (let i = 0; i < tags.length; i++) {
      if (!mappedTags.hasOwnProperty(tags[i].name)) {
        if (!tags[i].name || !tags[i].value) {
          setSnackbarMessage('Cannot save with empty tagnames or values.')
          setSnackbarVisible(true)
          return
        } else {
          mappedTags[tags[i].name] = tags[i].value
        }
      } else {
        setSnackbarMessage('Cannot save with duplicate tagnames.')
        setSnackbarVisible(true)
        return
      }
    }
    body.tags = mappedTags
    if (value === '' && unit !== 'Presence/Absence') {
      setSnackbarMessage('Cannot save with empty value.')
      setSnackbarVisible(true)
      return
    }
    const handleErrors = async (res) => {
      if (!res.ok) {
        let offlineCache = await AsyncStorage.getItem('@mushrooms_offlineCache').then(val => JSON.parse(val))
        let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
        offlineCache = { ...offlineCache, [property]: [...(offlineCache[property] ? offlineCache[property] : []), body] }
        await AsyncStorage.setItem('@mushrooms_offlineCache', JSON.stringify(offlineCache))
        onDismiss()
        setSnackbarMessage('Error encountered. Saved locally.')
        setSnackbarVisible(true)
        return
      }
      return res
    }
    if (props.isConnected) {
      let options = {
        headers: {
          'X-Access-Token': props.accessToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body.measureTypeId ? { records: [body] } : body.cropInputTypeId ? { cropInputs: [body] } : { cropOutputs: [body] })
      }
      fetch(`${props.dbUrl}/api/${body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'}`, options)
        .then(handleErrors)
        .then(res => res.text())
        .then(async data => {
          console.log(data)
          try {
            let info = JSON.parse(data)
            if (info.auth === false) {
              await props.updateTokens()
              onSubmit()
            }
            if (info.status === 'success') {
              let deviceSaves = await AsyncStorage.getItem('@mushrooms_deviceSaves').then(val => JSON.parse(val))
              let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
              deviceSaves = { ...deviceSaves, [property]: [...(deviceSaves[property] ? deviceSaves[property] : []), info.data[0] ]}
              await AsyncStorage.setItem('@mushrooms_deviceSaves', JSON.stringify(deviceSaves))
              onDismiss()
              setSnackbarMessage('Sent successfully.')
              setSnackbarVisible(true)
              props.setSaves(props.saves + 1)
              setLastItem({ ...info.data[0], type: property })
            }
          } catch(err) {
            console.error(err)
            if (err.message === 'Authentication failed.') {
              authContext.setIsSignedIn(false)
              authContext.setIsLoaded(false)
            }
            setSnackbarMessage('Failed to send.')
            setSnackbarVisible(true)
          }
        })
    } else {
      let offlineCache = await AsyncStorage.getItem('@mushrooms_offlineCache').then(val => JSON.parse(val))
      let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
      offlineCache = { ...offlineCache, [property]: [...(offlineCache[property] ? offlineCache[property] : []), body] }
      await AsyncStorage.setItem('@mushrooms_offlineCache', JSON.stringify(offlineCache))
      onDismiss()
      setSnackbarMessage('Saved locally.')
      setSnackbarVisible(true)
      setLastItem({ ...body, type: property })
    }
  }
  const onUndoPress = async () => {
    try {
      let location
      if (lastItem.id.split('-').length === 5) {
        location = '@mushrooms_offlineCache'
      } else {
        location = '@mushrooms_deviceSaves'
        await props.deleteSaveData(lastItem.id, lastItem.type)
      }
      let data = await AsyncStorage.getItem(location).then(val => JSON.parse(val))
      await AsyncStorage.setItem(
        location,
        JSON.stringify({
          ...data,
          [lastItem.type]: data[lastItem.type].filter(item => item.id !== lastItem.id)
        })
      )
      setSnackbarMessage('Deleted successfully.')
      setSnackbarVisible(true)
    } catch (err) {
      console.error(err)
    }
  }

  return (
  <View>
    <Portal>
      <Dialog visible={!!add} onDismiss={onDismiss}>
        <Dialog.ScrollArea style={{paddingHorizontal: 0}}>
          <ScrollView keyboardShouldPersistTaps={'always'}>
            <Dialog.Title>{'Add ' + String(add).split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')}</Dialog.Title>
            <Dialog.Content>
              <Dropdown
                value={pickerValue}
                onChange={onPickerChange}
                placeholder={`Select ${add} type...`}
                items={farmId &&
                  add === 'measure' ?
                    Object.values(indexedFarms[farmId]['measureTypes']).filter((measureType) => measureType.inUse && (sectionId ? measureType.measureSpaceType === 'Section' : measureType.measureSpaceType === 'Room')).map((measureType) => {
                      return { label: measureType.measureTypeName, value: measureType.id }
                    }) : add === 'crop input' ?
                    Object.values(indexedFarms[farmId]['cropInputTypes']).filter((cropInputType) => cropInputType.inUse && (sectionId ? cropInputType.cropInputSpaceType === 'Section' : cropInputType.cropInputSpaceType === 'Room')).map((cropInputType) => {
                      return { label: cropInputType.cropInputTypeName, value: cropInputType.id }
                    }) : add === 'crop output' ?
                    Object.values(indexedFarms[farmId]['cropOutputTypes']).filter((cropOutputType) => cropOutputType.inUse).map((cropOutputType) => {
                      return { label: cropOutputType.cropOutputTypeName, value: cropOutputType.id }
                    }) : []
                }
              />
              {
                unit === 'Presence/Absence' &&
                  <Dropdown
                    value={value === '' || value}
                    onChange={setValue}
                    style={{ marginVertical: 0, marginBottom: 12 }}
                    items={[
                      {
                        label: 'Presence',
                        value: true
                      },
                      {
                        label: 'Absence',
                        value: false
                      }
                    ]}
                  />
              }
              {
                (unit !== 'Presence/Absence' && unit !== '') &&
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <TextInput
                    value={value}
                    style={[styles.textInput, { marginBottom: 12 }]}
                    keyboardType="numeric"
                    placeholder={unit}
                    onChangeText={setValue}
                    maxLength={8}
                    returnKeyType="done"
                  />
                  {/* {sectionId} */}
                </View>
              }
              <View style={[{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 12 }]}>
                {
                  Platform.OS !== 'ios' &&
                    <View style={{ flex: 1, borderRadius: 4, overflow: 'hidden' }}>
                      <TextInput
                        value={time}
                        style={[styles.textInput, { height: 43, marginBottom: 0 }]}
                        placeholder={'HH:MM'}
                        showSoftInputOnFocus={false} //hide keyboard on focus
                        onChangeText={setTime}
                        onSubmitEditing={() => isValid('time')}
                        onTouchEnd={() => setTimePressed(true)}
                        maxLength={5}
                        returnKeyType="done"
                      />
                      <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton icon="clock-outline" size={20} color={'rgb(107, 107, 107)'} onPress={Platform.OS === 'android' && props.handleTimeTap} />
                      </View>
                      <Animated.View style={{ 
                        width: invalidTimeAnim.interpolate({
                          inputRange: [0, 1],
                          outputRange: ['0%', '100%']
                        }),
                        height: 3,
                        bottom: 0,
                        position: 'absolute',
                        backgroundColor: 'red'
                      }}></Animated.View>
                    </View>
                }
                {
                  (Platform.OS === 'ios' || (timePressed && Platform.OS !== 'ios')) &&
                    <DateTimePicker 
                      mode="time" 
                      style={{ zIndex: 999, flex: 1 }}
                      value={moment(time, ['HH:mm','h:mm','h:mm','hmm','HHmm'], true).toDate() || moment().toDate()} 
                      onChange={(e, result) => {
                        setTimePressed(false)
                        setTime(moment(result).format('HH:mm'))
                      }}
                    />
                }
                <View style={{ width: 24 }}></View>
                {
                  Platform.OS !== 'ios' &&
                    <View style={{ flex: 1, borderRadius: 4, overflow: 'hidden' }}>
                      <TextInput
                        value={date}
                        style={[styles.textInput, { height: 43, marginBottom: 0 }]}
                        placeholder={'MM/DD/YY'}
                        showSoftInputOnFocus={false} //hide keyboard on focus
                        onChangeText={setDate}
                        onSubmitEditing={() => isValid('date')}
                        onTouchEnd={() => setDatePressed(true)}
                        maxLength={8}
                        returnKeyType="done"
                      />
                      <View style={{ position: 'absolute', top: 0, right: 0 }}>
                        <IconButton icon="calendar" size={20} color={'rgb(107, 107, 107)'} onPress={Platform.OS === 'android' && props.handleDateTap} />
                      </View>
                      <Animated.View style={{ 
                        width: invalidDateAnim.interpolate({
                          inputRange: [0, 1],
                          outputRange: ['0%', '100%']
                        }),
                        backgroundColor: 'red',
                        height: 3,
                        bottom: 0,
                        position: 'absolute'
                      }}></Animated.View>
                    </View>
                }
                {
                  (Platform.OS === 'ios' || (datePressed && Platform.OS !== 'ios')) &&
                    <DateTimePicker 
                      mode="date" 
                      style={{ zIndex: 999, flex: 1 }}
                      value={moment(date, ['MM/DD/YYYY','MM/DD/YY','M/D/YYYY','M/D/YY'], true).toDate() || moment().toDate()}
                      onChange={(e, result) => {
                        setDatePressed(false)
                        setDate(moment(result).format('MM/DD/YY'))
                      }}
                    />
                }
              </View>
              {
                !sectionId &&
                  <TagEntry tags={tags} handleTags={setTags} />
              }
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={onDismiss}>Cancel</Button>
              <Button 
                style={{ paddingHorizontal: 12 }}
                mode="contained" 
                onPress={onSubmit}>Save</Button>
            </Dialog.Actions>
          </ScrollView>
        </Dialog.ScrollArea>
      </Dialog>
      <Snackbar 
        visible={snackbarVisible}
        duration={3000}
        action={(snackbarMessage === 'Sent successfully.' || snackbarMessage === 'Saved locally.') && {
          label: 'Undo',
          onPress: onUndoPress
        }}
        onDismiss={() => setSnackbarVisible(false)}
      >
        {snackbarMessage}
      </Snackbar>
    </Portal>
    </View>
  )
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    flexGrow: 5,
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  },
  BLEButton: { 
    flex: 1,
    margin: 6,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddModal)
