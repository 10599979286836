import React, { PureComponent } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export default class InfoCard extends PureComponent {
  render() {
    return (
      <View style={[styles.infoCard, this.props.style]}>
        <MaterialCommunityIcons name={this.props.iconName || 'information-outline'} size={24} />
        <Text style={styles.infoText}>{this.props.text}</Text>
        {
          !!this.props.buttonText &&
            <TouchableOpacity style={styles.chip} onPress={this.props.onButtonPress}>
              <Text style={styles.chipText}>{this.props.buttonText}</Text>
            </TouchableOpacity>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  infoCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    marginBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 48,
    paddingVertical: 24,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    shadowColor: '#000000',
    shadowOpacity: 0.25
  },
  infoText: {
    fontFamily: 'Roboto Regular',
    fontSize: 16,
    lineHeight: 22,
    color: 'rgba(0, 0, 0, 0.8)',
    textAlign: 'center',
    marginTop: 4
  },
  chip: {
    height: 30,
    minWidth: 96,
    backgroundColor: '#d5d5d5',
    paddingHorizontal: 8,
    borderRadius: 15,
    marginTop: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  chipText: {
    fontSize: 14,
    fontFamily: 'Roboto Regular',
    marginTop: Platform.OS !== 'ios' ? -1 : 0
  },
  chipContainer: {
    flexDirection: 'row'
  },
})
