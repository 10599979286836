import React from 'react'
import { 
  KeyboardAvoidingView, 
  ScrollView, 
  ActivityIndicator, 
  View, 
  Text, 
  TextInput,
  StatusBar,
  StyleSheet, 
  Image,
  ImageBackground,
  Dimensions,
  TouchableHighlight,
  TouchableWithoutFeedback, 
  TouchableOpacity 
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Shake } from 'react-native-motion'
import Constants from 'expo-constants'
import Images from './assets/images'
import AsyncStorage from './AsyncStorage'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect, ConnectedProps } from 'react-redux'
import AuthContext from './AuthContext'
import { AuthScreenProps as NavigationProps } from './App'

const connector = connect(mapStateToProps, mapDispatchToProps)
type AuthScreenProps = ConnectedProps<typeof connector> & NavigationProps
type AuthScreenState = {
  email: string,
  password: string,
  attempts: number,
  sending: boolean,
  configureDb: boolean,
  dbUrl: string,
  error: string
}

class AuthScreen extends React.Component<AuthScreenProps, AuthScreenState> {
  constructor(props: AuthScreenProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
      attempts: 0,
      sending: false,
      configureDb: false,
      dbUrl: props.dbUrl,
      error: ''
    }
  }
  componentDidUpdate(prevProps: AuthScreenState & AuthScreenProps) {
    if (this.props.isConnected !== prevProps.isConnected && this.props.isConnected) {
      this.setState({ error: '' })
    }
  }
  handleEmail = (email: string) => {
    this.setState({ email })
  }
  handlePassword = (password: string) => {
    this.setState({ password })
  }
  handleSubmit = async () => {
    try {
      if (!this.props.isConnected) {
        this.setState({ attempts: this.state.attempts + 1, error: 'Need internet connection to initialize farms.' })
        return
      }
      this.setState({ sending: true })
      let encoded = 'email=' + encodeURIComponent(this.state.email) + '&password=' + encodeURIComponent(this.state.password)
      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: encoded
      }
      let response = await fetch(`${this.props.dbUrl}/api/register`, options).then(res => res.json())
      if (response.auth) {
        // Save multiple items to local storage: access token, login time, email, and refresh token
        await AsyncStorage.multiSet([
          ['@mushrooms_accessToken', response.token],
          ['@mushrooms_loginTime', JSON.stringify(new Date())],
          ['@mushrooms_email', this.state.email],
          ['@mushrooms_refreshToken', response.refreshToken],
          ['@mushrooms_offlineCache', JSON.stringify({})],
          ['@mushrooms_deviceSaves', JSON.stringify({})]
        ])
        this.props.setAccessToken(response.token)
        this.props.setRefreshToken(response.refreshToken)
        this.props.setEmail(this.state.email)
        this.context.setIsSignedIn(true)
      } else {
        this.setState({ attempts: this.state.attempts + 1, sending: false })
      }
      if (response.auth === false) {
        this.setState({ error: 'Incorrect email or password.' })
      }
    } catch(err) {
      this.setState({ error: err.message, sending: false })
    }
  }
  render() {
    const { navigation } = this.props
    return (
      <SafeAreaView edges={['right', 'left']}>
        <StatusBar barStyle="dark-content" />
        <View style={styles.authContainer}>
          <ScrollView scrollEnabled={false} keyboardDismissMode="interactive" keyboardShouldPersistTaps={'handled'}>
            <ImageBackground
              source={Images['backgrounds']['shrooms-close']}
              style={styles.imageBackground}>
              <KeyboardAvoidingView behavior="position" enabled>
                <Shake value={this.state.attempts}>
                  <View style={styles.formContainer}>
                    <Image
                      source={Images['logos']['simple-logo']}
                      style={styles.formLogo}
                    />
                    {!!this.state.error && <Text style={styles.errorText}>{this.state.error}</Text>}
                    <TextInput
                      style={styles.textInput}
                      onChangeText={(text) => this.handleEmail(text)}
                      placeholder="email"
                      keyboardType="email-address"
                      textContentType="username"
                      autoCapitalize = "none"
                    />
                    <TextInput
                      style={styles.textInput}
                      onChangeText={(text) => this.handlePassword(text)}
                      placeholder="password"
                      secureTextEntry={true}
                      textContentType="password"
                      onSubmitEditing={() => this.handleSubmit()}
                    />
                    <TouchableWithoutFeedback
                      onPress={() => navigation.navigate('Forgot')}>
                      <Text style={styles.formForgotText}>Forgot Password?</Text>
                    </TouchableWithoutFeedback>
                    <TouchableHighlight
                    // @ts-ignore cursor: 'progress'
                      style={[styles.formButton, this.state.sending && { cursor: 'progress' }]}
                      onPress={this.state.sending ? () => {} : () => this.handleSubmit()}>
                      <View>
                        {
                          !this.state.sending &&
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <MaterialCommunityIcons style={{ marginLeft: -4 }} name={'login'} size={20} color={'#FAFAFA'} />
                              <Text style={[styles.formButtonText, { marginLeft: 6 }]}>LOGIN</Text>
                            </View>
                        }
                        {
                          this.state.sending &&
                            <View style={{ height: 20 }}>
                              <ActivityIndicator size="small" color="#fafafa" />
                            </View>
                        }
                      </View>
                    </TouchableHighlight>
                  </View>
                </Shake>
              </KeyboardAvoidingView>
            </ImageBackground>
          </ScrollView>
        </View>
        <View style={{ position: 'absolute', bottom: 24, right: 24 }}>
          <TouchableOpacity style={styles.iconButton} onPress={() => this.props.navigation.navigate('Modal', { pressed: 'settings' })}>
            <MaterialCommunityIcons name={'database'} size={24} color={'black'} />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
  }
}

AuthScreen.contextType = AuthContext

const styles = StyleSheet.create({
  formLogo: {
    width: 300,
    height: 60,
    resizeMode: 'contain',
    marginVertical: 36
  },
  formContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (Dimensions.get('window').height - Constants.statusBarHeight) / 2,
    backgroundColor: '#fafafa',
    shadowColor: '#000000',
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginHorizontal: 24,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  formButton: {
    borderRadius: 4,
    backgroundColor: '#222e3d',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12
  },
  formButtonText: {
    fontFamily: 'Roboto Bold',
    color: 'white',
    fontSize: 16,
    letterSpacing: 1.25
  },
  authContainer: {
    justifyContent: 'center',
    height: Dimensions.get('window').height
  },
  errorText: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    textAlign: 'center',
    marginBottom: 12,
    color: 'rgb(153, 17, 2)'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: 240,
    backgroundColor: '#eaeaea',
    textAlign: 'center',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12
  },
  formForgotText: {
    fontSize: 14,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    marginVertical: 12
  },
  imageBackground: {
    width: '100%',
    height: Dimensions.get('window').height,
    justifyContent: 'center'
  },
  iconButton: {
    padding: 12,
    borderRadius: 24,
    backgroundColor: '#fafafa',
    shadowColor: '#000000',
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen)
