import React from 'react'
import { View, Text, Dimensions, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

const { width } = Dimensions.get('window')

const OfflineNotice = () => {
  const isConnected = useSelector(state => state.isConnected)
  if (!isConnected) {
    return (
      <View style={styles.offlineContainer}>
        <Text style={styles.offlineText}>Offline Mode</Text>
      </View>
    )
  } else {
    return (
      <View></View>
    )
  }
}

const styles = StyleSheet.create({
  offlineContainer: {
    backgroundColor: '#888888',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width,
  },
  offlineText: {
    color: '#fff',
    fontFamily: 'Roboto Regular'
  },
})

export default OfflineNotice