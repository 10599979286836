import React, { useContext, useState, useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { FarmCard, InfoCard, Header } from './components'
import _ from 'underscore'
import AsyncStorage from './AsyncStorage'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect, ConnectedProps } from 'react-redux'
import AuthContext from './AuthContext'
import { FarmScreenProps as NavigationProps } from './App'

const connector = connect(mapStateToProps, mapDispatchToProps)
type FarmScreenProps = ConnectedProps<typeof connector> & NavigationProps

const FarmsScreen = (props: FarmScreenProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [farms, setFarms] = useState([])
  const authContext = useContext(AuthContext)
  const retrieveData = async () => {
    let farms
    try {
      if (await props.farmsUpdated()) {
        ({ farms } = await props.updateFarms())
      }
    } catch (err) {
      if (err.message === 'Authentication failed.') {
        authContext.setIsSignedIn(false)
        authContext.setIsLoaded(false)
      }
    }
    if (!farms) {
      farms = await AsyncStorage.getItem('@mushrooms_farms').then(val => JSON.parse(val))
      if (farms === null) {
        farms = []
        await AsyncStorage.setItem('@mushrooms_farms', JSON.stringify(farms))
      }
    }
    farms.sort((a, b) => a.name.localeCompare(b.name))
    setFarms(farms)
    setIsLoaded(true)
  }
  useEffect(() => {
    if (!isLoaded) {
      retrieveData()
    }
  }, [isLoaded])
  const { accessToken } = props
  useEffect(() => {
    retrieveData()
  }, [accessToken])
  const navigate = (farmId = '') => {
    props.navigation.navigate('Buildings', { farmId })
  }
  return (
    <SafeAreaView edges={['right', 'bottom', 'left']} style={styles.container}>
      <ScrollView keyboardDismissMode="interactive" persistentScrollbar={true} contentContainerStyle={styles.cardScroll}>
      <View style={styles.viewPadding}/>
        {
          farms.length > 0 &&
            <InfoCard text={'Select a farm below or view ALL buildings for ALL farms'} buttonText={'View All'} onButtonPress={() => navigate()} />
        }
        {
          farms.length === 0 && !isLoaded &&
            <ActivityIndicator size="small" color="#222e3d" />
        }
        {
          farms.length === 0 && isLoaded &&
            <InfoCard iconName="alert-outline" text="Looks like there are no farms." />
        }
        {
          farms.map((farm) => (
            <View key={farm.id}>
              <FarmCard farm={_.pick(farm, 'id', 'name')} navigate={navigate} />
            </View>
          ))
        }
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    flex: 1
  },
  viewPadding: {
    paddingTop: 24
  },
  cardScroll: {
    paddingHorizontal: 24,
    paddingBottom: 12,
    flexGrow: 1
  },
  farmOptions: {
    justifyContent: 'flex-end'
  },
  farmActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
    marginHorizontal: 12,
  },
  actionButtonText1: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#43425D',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  actionButtonText2: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: 'white'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FarmsScreen)
