import React from 'react'
import { SafeAreaView, View, StyleSheet, Dimensions } from 'react-native'
import { Button, Dialog, Portal, Snackbar, Modal, Text, RadioButton } from 'react-native-paper'
import { connect, ConnectedProps } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { DataView, DeviceDataPuller, DeviceListView } from './components'
import AsyncStorage from './AsyncStorage'
import AuthContext from './AuthContext'
import { ModalScreenProps as NavigationProps } from './App'

const connector = connect(mapStateToProps, mapDispatchToProps)
type ModalScreenProps = ConnectedProps<typeof connector> & NavigationProps

type ModalScreenState = {
  visible: boolean
  snackbarMessage: string
  snackbarVisible: boolean
  snackbarDuration: number
  dbUrl: string
  rescanPressed: boolean
}

class ModalScreen extends React.Component<ModalScreenProps, ModalScreenState> {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      snackbarMessage: '',
      snackbarVisible: false,
      snackbarDuration: 3000,
      dbUrl: this.props.dbUrl,
      rescanPressed: false
    }
  }

  async componentDidMount() {
    // check for relevant ble permission before you launch the modal
    if(this.props.route.params.pressed === 'pull' || this.props.route.params.pressed === 'device') {
      await this.props.checkBlePermissions()
    }
    //@ts-ignore
    this.setState({ [`${this.props.route.params.pressed}Pressed`]: true })
  }

  handleSnackbarVisible = (snackbarMessage = 'Saved locally.', snackbarDuration = 3000) => {
    if (this.state.snackbarVisible) {
      // If the snackbar is already visible, make it invisible to update text
      this.setState({ snackbarVisible: false, snackbarDuration }, () => {
        this.handleSnackbarVisible(snackbarMessage)
      })
    }
    // Otherwise just make it visible and update text 
    this.setState({ snackbarVisible: true, snackbarMessage, snackbarDuration })
  }

  handleViewCachePressed = () => {
    //@ts-ignore
    this.setState({ cachePressed: !this.state.cachePressed }) 
    this.props.navigation.goBack()
  }

  handleViewDataPressed = () => {
    this.setState(
      { dataPressed: !this.state.dataPressed },
      () => this.props.navigation.goBack()
    )
  }

  handleSettingsPressed = () => {
    //@ts-ignore
    this.setState({ settingsPressed: !this.state.settingsPressed, dbUrl: this.props.dbUrl })
    this.props.navigation.goBack()
  }

  handleLogoutPressed = () => {
    //@ts-ignore
    this.setState({ logoutPressed: !this.state.logoutPressed })
    this.props.navigation.goBack()
  }

  handleLogout = async () => {
    //@ts-ignore
    this.setState({ logoutPressed: !this.state.logoutPressed })
    this.props.navigation.goBack()
    this.props.setAccessToken('')
    this.props.setRefreshToken('')
    this.props.setEmail('')
    await AsyncStorage.clear()
    this.context.setIsSignedIn(false)
    this.context.setIsLoaded(false)
  }

  handleConfigureDb = async () => {
    if (this.state.dbUrl !== this.props.dbUrl) {
      this.props.setDbUrl(this.state.dbUrl)
      await AsyncStorage.setItem('@mushrooms_dbUrl', this.state.dbUrl)
      //@ts-ignore
      this.setState({ configureDb: !this.state.configureDb })
      this.handleLogout()
    }
    else {
      this.handleSnackbarVisible('URL was not changed.', 900)
      console.log(this.state.snackbarDuration)
      setTimeout(this.handleSettingsPressed, 1000)
    }
  }

  handleRescanPressed = () => {
    this.setState({ rescanPressed: true })
    setTimeout(() => {
      this.setState({ rescanPressed: false })
    }, 200)
  }

  handleDevicePressed = () => {
    //@ts-ignore
    this.setState({ devicePressed: !this.state.devicePressed })
    this.props.navigation.goBack()
  }

  render() {
    return (
      <SafeAreaView>
        <Portal>
          <Modal visible={this.state.cachePressed} onDismiss={this.handleViewCachePressed} contentContainerStyle={styles.modal}>
            <Dialog.Title>
              Unsent Saves
            </Dialog.Title>
            <Dialog.ScrollArea style={{ maxHeight: Dimensions.get('window').height * (3 / 4), paddingHorizontal: 0 }}>
              <DataView
                dbUrl={this.props.dbUrl}
                state='cache'
                handleSnackbarVisible={this.handleSnackbarVisible}
              />
            </Dialog.ScrollArea>
            <Dialog.Actions>
              <Button onPress={this.handleViewCachePressed}>Cancel</Button>
            </Dialog.Actions>
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={this.state.dataPressed} onDismiss={this.handleViewDataPressed} contentContainerStyle={styles.modal}>
            <Dialog.Title>
              History
            </Dialog.Title>
            <Dialog.ScrollArea style={{ maxHeight: Dimensions.get('window').height * (3 / 4), paddingHorizontal: 0 }}>
              <DataView
                dbUrl={this.props.dbUrl}
                state='saves'
                handleSnackbarVisible={this.handleSnackbarVisible}
              />
            </Dialog.ScrollArea>
            <Dialog.Actions>
              <Button onPress={this.handleViewDataPressed}>Cancel</Button>
            </Dialog.Actions>
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={this.state.devicePressed} onDismiss={this.handleDevicePressed} contentContainerStyle={styles.modal}>
            <Dialog.Title>
              Devices
            </Dialog.Title>
            <Dialog.ScrollArea style={{ maxHeight: Dimensions.get('window').height * (3 / 4), paddingHorizontal: 0 }}>
              {/*<DeviceDataView />*/}
              {this.state.rescanPressed === false && 
                <DeviceListView addModalThermoSelecting={this.props.route.params.addModalThermoSelecting}/>
              }
            </Dialog.ScrollArea>
            <Dialog.Actions>
              <Button onPress={this.handleRescanPressed}>Rescan</Button>
              <Button onPress={this.handleDevicePressed}>Cancel</Button>
            </Dialog.Actions>
          </Modal>
        </Portal>

        <Portal>
          <Modal visible={this.state.pullPressed} onDismiss={this.handleDevicePressed} contentContainerStyle={styles.modal}>
            <Dialog.Title>
              Pull Device Measures
            </Dialog.Title>
            <Dialog.ScrollArea style={{ maxHeight: Dimensions.get('window').height * (3 / 4), paddingHorizontal: 0 }}>
              {this.state.rescanPressed === false &&
                <DeviceDataPuller />
              }
            </Dialog.ScrollArea>
            <Dialog.Actions>
              <Button onPress={this.handleRescanPressed}>Rescan</Button>
              <Button onPress={this.handleDevicePressed}>Cancel</Button>
            </Dialog.Actions>
          </Modal>
        </Portal>

        <Portal>
          <Dialog visible={this.state.logoutPressed} onDismiss={this.handleLogoutPressed}>
            <Dialog.Title>
              Are you sure you want to log out?
            </Dialog.Title>
            <Dialog.Content>
              <Text>
                You will not be able to view your previous saves after you log out and any unsent saves will be deleted!
              </Text>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={this.handleLogoutPressed}>Cancel</Button>
              <Button onPress={this.handleLogout}>Log Out</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>

        <Portal>
          <Dialog visible={this.state.settingsPressed} onDismiss={this.handleSettingsPressed}>
            <Dialog.Title>
              Configure Cropsmarts URL
            </Dialog.Title>
            <Dialog.Content>
              <Text>
                You will not be able to view your previous saves after you switch database locations and any unsent saves will be deleted!
              </Text>
            </Dialog.Content>
            <Dialog.Content>
              <RadioButton.Group 
                onValueChange={value => this.setState({ dbUrl: value })}
                value={this.state.dbUrl}  
              >
                <RadioButton.Item 
                  label="Production" 
                  value="https://www.cropsmarts.com" 
                  color='#d602ee'
                />
                <RadioButton.Item
                  label="Development"
                  value="https://dev.cropsmarts.com"
                  color='#d602ee'
                />
              </RadioButton.Group>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={this.handleConfigureDb}>Submit</Button>
            </Dialog.Actions>
          </Dialog>
          <Snackbar
            visible={this.state.snackbarVisible}
            onDismiss={() => this.setState({ snackbarVisible: !this.state.snackbarVisible })}
            duration={this.state.snackbarDuration}
          >
            {this.state.snackbarMessage}
          </Snackbar>
        </Portal>
      </SafeAreaView>
    )
  }
}

ModalScreen.contextType = AuthContext

const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'white',
    marginHorizontal: 18,
    borderRadius: 4
  },
  dataView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 18
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalScreen)

