import React, { useState, useEffect, useCallback } from 'react'
import { View, StyleSheet, ActivityIndicator, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { BuildingCard, InfoCard, Header } from './components'
import _ from 'underscore'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect } from 'react-redux'
import AsyncStorage from './AsyncStorage'
import { useFocusEffect } from '@react-navigation/native'

const BuildingsScreen = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [buildings, setBuildings] = useState([])
  const { farmId } = props.route.params || {}
  const { indexedFarms } = props
  const loadAsync = async () => {
    let buildings = []
    if (farmId) {
      buildings = Object.values(indexedFarms[farmId]['buildings'])
    } else {
      let farms = await AsyncStorage.getItem('@mushrooms_farms').then(val => JSON.parse(val))
      farms.forEach((farm) => buildings.push(...farm.buildings))
    }
    buildings.sort((a, b) => a.name.localeCompare(b.name))
    setBuildings(buildings)
    setIsLoaded(true)
  }
  useEffect(() => {
    if (!isLoaded) {
      loadAsync()
    }
  }, [isLoaded])
  useFocusEffect(
    useCallback(() => {
      if (isLoaded) {
        loadAsync()
      }
    }, [farmId])
  )
  const navigate = (buildingId = '', farmId = '') => {
    if (!farmId && buildingId) {
      farmId = Object.values(indexedFarms).filter((farm) => farm['buildings'][buildingId])[0]['farmId']
    }
    props.navigation.navigate('Rooms', { farmId, buildingId })
  }
  return (
    <SafeAreaView edges={['right', 'bottom', 'left']} style={styles.container}>
      <ScrollView keyboardDismissMode="interactive" persistentScrollbar={true} contentContainerStyle={styles.cardScroll}>
        <View style={styles.viewPadding}/>
        {
          buildings.length > 0 &&
            <InfoCard text={'Select a building below or view ALL rooms for ALL buildings'} buttonText={'View All'} onButtonPress={() => navigate('', farmId)} />
        }
        {
          buildings.length === 0 && !isLoaded &&
            <ActivityIndicator size="small" color="#222e3d" />
        }
        {
          buildings.length === 0 && isLoaded &&
            <InfoCard iconName="alert-outline" text="Looks like there are no buildings in this farm." buttonText="Go Back" onButtonPress={() => navigation.goBack()} />
        }
        {
          buildings.map((building) => (
            <View key={building.id}>
              <BuildingCard
                building={_.pick(building, 'id', 'name', 'farmId')}
                farmName={indexedFarms[building.farmId]['name']}
                navigate={navigate}
              />
            </View>
          ))
        }
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    flex: 1
  },
  viewPadding: {
    paddingTop: 24
  },
  cardScroll: {
    paddingHorizontal: 24,
    paddingBottom: 12,
    flexGrow: 1
  },
  farmOptions: {
    justifyContent: 'flex-end'
  },
  farmActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
    marginHorizontal: 12,
  },
  actionButtonText1: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#43425D',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  actionButtonText2: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: 'white'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginTop: 12,
    marginBottom: 24
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsScreen)
