import React from 'react'
import { View, Text, ScrollView, KeyboardAvoidingView, TextInput, StatusBar, ActivityIndicator, StyleSheet, Button, Image, ImageBackground, Dimensions, TouchableHighlight, TouchableOpacity, Keyboard } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import Constants from 'expo-constants'
import Images from './assets/images'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect, ConnectedProps } from 'react-redux'
import AsyncStorage from './AsyncStorage'
import { ForgotScreenProps as NavigationProps } from './App'

const connector = connect(mapStateToProps, mapDispatchToProps)
type ForgotScreenProps = ConnectedProps<typeof connector> & NavigationProps
type AuthScreenState = {
  email: string,
  password: string,
  attempts: number,
  sending: boolean,
  configureDb: boolean,
  dbUrl: string,
  error: string, 
  message: string
}

class ForgotScreen extends React.Component<ForgotScreenProps, AuthScreenState> {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      sending: false
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isConnected !== prevProps.isConnected && this.props.isConnected) {
      this.setState({ error: '' })
    }
  }
  handleEmail = (email) => {
    this.setState({ email })
  }
  async handleSubmit() {
    try {
      if (!this.props.isConnected) {
        this.setState({ attempts: this.state.attempts + 1, error: 'Need internet connection to request recovery email.', message: '' })
        return
      }
      if (this.state.email) {
        if (this.state.email.includes('@') && this.state.email.includes('.')) {
          let encoded = 'email=' + encodeURIComponent(this.state.email)
          this.setState({ sending: true })
          let options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: encoded
          }
          let res = await fetch(`${this.props.dbUrl}/forgot-password`, options)
          this.setState({ sending: false, message: 'Recovery email sent.', error: '' })
        } else {
          this.setState({ error: 'Not a valid email.', message: '' })
        }
      } else {
        this.setState({ error: 'Not a valid email.', message: '' })
      }
    } catch(err) {

    }
  }
  render() {
    const { navigation } = this.props
    return (
      <SafeAreaView edges={['right', 'left']}>
        <StatusBar />
        <View style={styles.authContainer}>
          <ScrollView scrollEnabled={false} keyboardDismissMode="interactive" keyboardShouldPersistTaps={'handled'}>
            <ImageBackground
              source={Images['backgrounds']['shrooms-close']}
              style={styles.imageBackground}>
              <KeyboardAvoidingView behavior="position" enabled>
                <View style={styles.formContainer}>
                  <Image
                    source={Images['logos']['simple-logo']}
                    style={styles.formLogo}
                  />
                  {!!this.state.error && <Text style={styles.errorText}>{this.state.error}</Text>}
                  {!!this.state.message && <Text style={styles.messageText}>{this.state.message}</Text>}
                  <TextInput
                    style={styles.textInput}
                    onBlur={Keyboard.dismiss}
                    onChangeText={(text) => this.handleEmail(text)}
                    placeholder="email"
                    keyboardType="email-address"
                    textContentType="username"
                    autoCapitalize = "none"
                    onSubmitEditing={this.state.sending ? () => {} : () => this.handleSubmit()}
                  />
                  <TouchableOpacity
                    onPress={() => navigation.goBack()}>
                    <Text style={styles.formForgotText}>Go Back</Text>
                  </TouchableOpacity>
                  <TouchableHighlight
                    style={[styles.formButton, this.state.sending && { cursor: 'progress' }]}
                    onPress={this.state.sending ? () => {} : () => this.handleSubmit()}>
                    <View style={{ height: 20 }}>
                      {!this.state.sending && <Text style={styles.formButtonText}>SEND EMAIL</Text>}
                      {this.state.sending && <ActivityIndicator size="small" color="#fafafa" />}
                    </View>
                  </TouchableHighlight>
                </View>
              </KeyboardAvoidingView>
            </ImageBackground>
          </ScrollView>
        </View>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  formLogo: {
    width: 300,
    height: 60,
    resizeMode: 'contain',
    marginVertical: 36
  },
  formContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (Dimensions.get('window').height - Constants.statusBarHeight) / 2,
    backgroundColor: '#fafafa',
    shadowColor: '#000000',
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginHorizontal: 24,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  formButton: {
    borderRadius: 4,
    backgroundColor: '#222e3d',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 120,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12
  },
  formButtonText: {
    fontFamily: 'Roboto Bold',
    color: 'white',
    fontSize: 16,
    letterSpacing: 1.25
  },
  authContainer: {
    justifyContent: 'center',
    height: Dimensions.get('window').height
  },
  errorText: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    textAlign: 'center',
    marginBottom: 12,
    color: 'rgb(153, 17, 2)'
  },
  messageText: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    textAlign: 'center',
    marginBottom: 12,
    color: 'rgb(17, 153, 2)'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: 240,
    backgroundColor: '#eaeaea',
    textAlign: 'center',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12
  },
  formForgotText: {
    fontSize: 14,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    marginVertical: 12
  },
  imageBackground: {
    width: '100%',
    height: Dimensions.get('window').height,
    justifyContent: 'center'
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotScreen)
