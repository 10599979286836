import React from 'react'
import { View, StyleSheet, Image, TouchableWithoutFeedback, Platform } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import Images from '../assets/images'
import { useNavigation } from '@react-navigation/native'

const AppBar = () => {
  const navigation = useNavigation()
  return (
    <View style={[styles.titleContainer, Platform.OS !== 'android' && { zIndex: 1 }]}>
      <TouchableWithoutFeedback onPress={navigation.toggleDrawer}>
        <View style={{ position: 'absolute', top: 17.5, left: 24, alignItems: 'center' }}>
          <MaterialCommunityIcons style={{ height: 25, paddingVertical: 0.5 }} name={'menu'} size={24} color={'white'} />
        </View>
      </TouchableWithoutFeedback>
      <Image
        source={Images['logos']['simple-logo-white']}
        style={styles.titleImage}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    backgroundColor: '#43425D',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: '100%',
    zIndex: 2
  },
  titleImage: {
    width: 125,
    height: 25,
    resizeMode: 'contain'
  },
  close: {
    marginLeft: 24,
    marginBottom: 17.5
  }
})

export default AppBar
