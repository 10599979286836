import React, { useState, useEffect } from 'react'
import { View, FlatList, ActivityIndicator, NativeModules, Platform } from 'react-native'
import { DataViewCard, DeviceLocation } from '.'
import { connect, ConnectedProps } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import moment from 'moment'
import AsyncStorage from '../AsyncStorage'
import uuid from 'uuid/v4'
import { Caption, Paragraph } from 'react-native-paper'
import { Device } from 'react-native-ble-plx'
import { AgriLocation, AgriReadData } from '../type/agri'
import { IndexedMeasureTypes } from '../type/api/indexedFarms'
import { Square } from '../type/api/farms'
import { DeviceSaves } from '../type/history'
import { Record, ApiRecordFetch } from '../type/api/records'
import { Buffer } from 'buffer'

const connector = connect(mapStateToProps, mapDispatchToProps)
interface DeviceDataViewOwnProps {
  device: Device
  deviceType: 'agri' | 'thermo' | 'unknown'
  addModalThermoSelecting: boolean
}
type DeviceDataViewProps = ConnectedProps<typeof connector> & DeviceDataViewOwnProps

const DeviceDataView = (props: DeviceDataViewProps) => {
  const [data, setData] = useState([] as Record[])
  const [isLoaded, setIsLoaded] = useState(false)
  const [indexedMeasures, setIndexedMeasures] = useState({} as IndexedMeasureTypes)
  const [rooms, setRooms] = useState({} as IndexedRooms)
  const [sections, setSections] = useState({} as IndexedSections)
  const [scanning, setScanning] = useState(true)
  const [location, setLocation] = useState({} as typeof format)
  const [options, setOptions] = useState({ farms: [] as optionLabel[], buildings: [] as optionLabel[], rooms: [] as optionLabel[] })
  const { indexedFarms } = props
  
  type optionLabel = {
    label: string
    value: string
  }

  type IndexedRooms = { 
    [key: string]: { name: string, farmId: number, buildingId: string} | {}
  }
  type IndexedSections = {
    [key: string]: Square | {}
  }

  const gatherFromAgri = () => {
    return new Promise<Record[]>(async (resolve, reject) => {
      try {
        await props.connectToDevice(props.device)
        setScanning(false)
        let data: AgriReadData = await props.readAndCollect().then(val => JSON.parse(val)).catch(err => { return {} })
        let { roomId, squareId } = data.location || {}
        let _location = format(data.location)
        let { farmId } = _location
        if (!roomId) {
          format()
          return resolve([])
        }
        let records: Record[] = []
        let newIndexedMeasures: IndexedMeasureTypes = {}
        let { measureTypes } = indexedFarms[farmId]
        for (let measureTypeId in measureTypes) {
          let { measureTypeName } = measureTypes[measureTypeId]
          if (data[measureTypeName.toLowerCase()] !== undefined) {
            let body: Record = { 
              measureTypeId, 
              roomId, 
              id: uuid() as string,
              doubleMeasure: data[measureTypeName.toLowerCase()],
              recordedAt: moment().format(),
            }
            newIndexedMeasures[measureTypeId] = measureTypes[measureTypeId]
            if (squareId) body.squareId = squareId
            records.push(body)
          }
        }
        setIndexedMeasures({ ...indexedMeasures, ...newIndexedMeasures })
        resolve(records)
        format(data.location)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  interface ThermoRecord {
    doubleMeasure: number,
    recordedAt: string,
    measureTypeName: string,
    id: string
  }

  const gatherFromThermoAndroid = () => {
    const { BLEModule } = NativeModules
    return new Promise<ThermoRecord[]>(async (resolve, reject) => {
      try {
        let device: Device = props.device as Device
        await BLEModule.setAddress(device.id)
        BLEModule.scanAndGrabFromThermo(
          (err: string, thermoData: number) => {
            if (!err) {
              console.log({ thermoData })
              
              let records: ThermoRecord[] = [{ 
                doubleMeasure: thermoData, 
                recordedAt: moment().format(), 
                measureTypeName: 'temperature',  
                id: uuid()
              }]
              resolve(records)
              setScanning(false)
              BLEModule.stopScanLeDevice()
            }
            else
              console.error(err)
          }
        )
        setTimeout(() => { 
          BLEModule.stopScanLeDevice() 
        }, 15000)
        
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  const gatherFromThermoIOS = () => {
    return new Promise<ThermoRecord[]>(async (resolve, reject) => {
      let device: Device = props.device as Device
      const rawData = Buffer
        .from(String(device.manufacturerData), 'base64')
      const rawDataInHex = rawData.toString('hex')
      const tempInHex = rawDataInHex.substring(20, 24)
      const tempTimes100 = parseInt(`0x${tempInHex}`)
      if (tempTimes100 === NaN) {
        reject() // should never happen because the value is a parsed hex.
      }
      const temp = tempTimes100 / 100
      let records: ThermoRecord[] = [{
        doubleMeasure: temp,
        recordedAt: moment().format(),
        measureTypeName: 'temperature',
        id: uuid()
      }]
      setScanning(false)
      resolve(records)
    })
  }

  /**
   * Send a record to mushserver. 
   * 
   * If the device is not connected to the internet,
   * save data to cache, and if there is an error at sending,
   * save to cache. Otherwise record the data directly. 
   * @param {Record[]} records 
   */
  const send = async (records: Record[]): Promise<Record[]> => {
    const cacheData = async () => {
      let offlineCache: DeviceSaves = await AsyncStorage
        .getItem('@mushrooms_offlineCache')
        .then(val => JSON.parse(val))
      let property = 'records'
      offlineCache = {
        ...offlineCache,
        [property]: [
          ...(offlineCache[property] ? offlineCache[property] : []),
          ...records
        ]
      }
      await AsyncStorage.setItem(
        '@mushrooms_offlineCache',
        JSON.stringify(offlineCache)
      )
      return records
    }
    if (props.isConnected) {
      //helper function for caching data
      let options = {
        headers: {
          'X-Access-Token': props.accessToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ records })
      }
      const res = await fetch(`${props.dbUrl}/api/records`, options)
      if (!res.ok) {
        //TODO: inform user that the data failed to send
        return await cacheData()
      }
      const data = await res.text()
      try {
        const info: ApiRecordFetch = JSON.parse(data)
        //@ts-expect-error why are we checking info.auth here? only a refresh token request has that.
        if (info.auth === false) {
          await props.updateTokens()
          return await send(records)
        }
        if (info.status === 'success') {
          let deviceSaves: DeviceSaves = await AsyncStorage
            .getItem('@mushrooms_deviceSaves')
            .then(val => JSON.parse(val))
          let property = 'records'
          deviceSaves = {
            ...deviceSaves,
            [property]: [
              ...(deviceSaves[property] ? deviceSaves[property] : []),
              info.data
            ]
          }
          await AsyncStorage.setItem(
            '@mushrooms_deviceSaves',
            JSON.stringify(deviceSaves)
          )
          props.setSaves(props.saves + 1)
          return info.data
        }
      } catch (err) {
        console.error(err)
        if (err.message === 'Authentication failed.') {
          // authContext.setIsSignedIn(false)
          // authContext.setIsLoaded(false)
        }
        return await cacheData()
      }
    } else {
      return await cacheData()
    }
  }

  const format = (location = {} as AgriLocation) => {
    let roomsChanged = false, sectionsChanged = false
    let { roomId, squareId } = location
    if (!rooms[roomId]) {
      roomsChanged = true
    }
    if (squareId) {
      if (!sections[squareId]) {
        sectionsChanged = true
      }
    }
    if (roomsChanged || sectionsChanged) {
      let newRooms = {} as IndexedRooms , newSections = {} as IndexedSections
      newRooms[roomId] = {}
      if (squareId) newSections[squareId] = {}
      let options = { farms: [] as optionLabel[], buildings: [] as optionLabel[], rooms: [] as optionLabel[] }
      let farmId: string, buildingId: string
      Object.values(indexedFarms).forEach(farm => {
        options.farms.push({ label: farm.name, value: String(farm.id) })
        Object.values(farm.buildings).forEach(building => {
          options.buildings.push({ label: building.name, value: building.id })
          building.rooms.forEach(room => {
            options.rooms.push({ label: room.name, value: room.id })
            if (newRooms[room.id]) {
              newRooms[room.id] = {
                name: room.name,
                farmId: Number(farm.id),
                buildingId: building.id
              }
            }
            if (Number(room.id) == Number(roomId)) {
              farmId = String(farm.id)
              buildingId = building.id
            }
            if (squareId) {
              room.rows.forEach(row => {
                row.levels.forEach(level => {
                  level.squares.forEach(square => {
                    if (newSections[square.id]) newSections[square.id] = square
                  })
                })
              })
            }
          })
        })
      })
      setOptions(options)
      setRooms({ ...rooms, ...newRooms })
      setSections({ ...sections, ...newSections })
      const newLocation = { farmId, buildingId, roomId, squareId }
      setLocation(newLocation)
      return newLocation
    }
  }
  const loadAgriAsync = async () => {
    try {
      let records = await gatherFromAgri()
      let sent = await send(records)
      setData([...sent, ...data])
      setIsLoaded(true)
    } catch (err) {
      console.error(err)
    }
  }
  const loadThermoAsync = async () => {
    try {
      let records = 
        Platform.OS === 'android' ? 
        await gatherFromThermoAndroid() : 
        await gatherFromThermoIOS()
      //@ts-ignore-error
      setData([...records, ...data])
      setIsLoaded(true)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (!isLoaded) {
      if(props.deviceType === 'agri')
        loadAgriAsync()
      else if(props.deviceType === 'thermo') {
        loadThermoAsync()
      }
      else if(props.deviceType === 'unknown') {
        // unlikely to happen, so just assume that the device is an agri.
        loadAgriAsync()
      }
    }
  }, [isLoaded])
  useEffect(() => {
    // let timeout = setTimeout(async () => {
    //   let records = await gather()
    //   let sent = await send(records)
    //   setData([...sent, ...data])
    // }, 3000)
    return function cleanup() {
      // clearTimeout(timeout)
      if(props.deviceType === 'agri')
        props.disconnectDevice()
    }
  }, [data])
  const deleteData = async (value) => {
    setData(data.filter(item => item.id !== value.id))
    props.deleteSaveData(value.id, 'records')
    let deviceSaves = await AsyncStorage.getItem('@mushrooms_deviceSaves').then(val => JSON.parse(val))
    await AsyncStorage.setItem(
      '@mushrooms_deviceSaves',
      JSON.stringify({
        ...deviceSaves,
        records: deviceSaves.records.filter(item => item.id !== value.id)
      })
    )
  }

  type listProps = {
    ListHeaderComponent?: JSX.Element
    ListFooterComponent?: JSX.Element
  }

  if(props.deviceType === 'agri') {
    const renderItem = ({ item }: { item: Record }) => (
      <DataViewCard
        value={{
          ...item,
          type: 'records',
          typeName: indexedMeasures[item.measureTypeId]['measureTypeName']
        }}
        rooms={rooms}
        sections={sections}
        handleDeleteData={deleteData}
      />
    )
    let listProps: listProps = {}
    if (data.length > 0) {
      listProps = {
        ListFooterComponent: <View style={{ height: 6 }}></View>
      }
    }
    if (scanning) {
      listProps.ListHeaderComponent = (
        <View style={{ alignItems: 'center', padding: 24 }}>
          <ActivityIndicator size="small" color="#888888" />
          <Caption>Connecting</Caption>
        </View>
      )
    } else {
      listProps.ListHeaderComponent = (
        <DeviceLocation
          options={options}
          location={location}
          setLocation={props.writeToDevice}
        />
      )
    }
    return (
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        {...listProps}
      />
    )
  } else if(props.deviceType === 'thermo') {
    const renderItem = ({ item }: { item: Record }) => (
      <DataViewCard
        value={{
          ...item,
          type: 'records',
          typeName: 'Temperature'
        }}
      />
    )
    let listProps: listProps = {}
    if (data.length > 0) {
      listProps = {
        ListFooterComponent: <View style={{ height: 6 }}></View>
      }
    }
    if (scanning) {
      listProps.ListHeaderComponent = (
        <View style={{ alignItems: 'center', padding: 24 }}>
          <ActivityIndicator size="small" color="#888888" />
          <Caption>Connecting</Caption>
        </View>
      )
    } else if(props.addModalThermoSelecting === true) {
      return (
        <View style={{ alignItems: 'center', padding: 24 }}>
          <Paragraph>Thermometer connected. Close this Modal and rescan to continue.</Paragraph>
        </View>
      )
    } else {
      listProps.ListHeaderComponent = (
        <View style={{ alignItems: 'center', padding: 24 }}>
          <Paragraph>Thermometer selected. Current Temperature in Celsius.</Paragraph>
        </View>
      )
    }
    return (
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        {...listProps}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDataView)