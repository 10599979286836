import { Reducer } from 'react'
import { createStore, applyMiddleware } from 'redux'
import { NativeModules, Platform } from 'react-native'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import NetInfo from '@react-native-community/netinfo'
import AsyncStorage from '../AsyncStorage'
import _ from 'underscore'
import * as Notifications from 'expo-notifications'
import * as Location from 'expo-location'
import { BleManager, Device } from 'react-native-ble-plx'
import { Buffer } from 'buffer'
import { IndexedFarmVersions } from '../type/api/indexedFarms'
import { MeResponse } from '../type/api/farms'
import moment from 'moment'
import uuid from 'uuid'

const checkIfBLEAvailable = () => {
  try {
    new BleManager()
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

const platformHasBle = false//(Platform.OS === 'ios' || Platform.OS === 'android') && checkIfBLEAvailable()

export interface StateProps {
  isConnected: boolean
  accessToken: string
  refreshToken: string
  email: string
  saves: number
  dbUrl: string
  indexedFarms: IndexedFarms
  bleManager: BleManager
  device: Device | {}
  thermo: Device | null
  deviceList: Device[] | []
}

const initialState: StateProps = {
  isConnected: false,
  accessToken: '',
  refreshToken: '',
  email: '',
  saves: 0,
  dbUrl: 'https://www.cropsmarts.com',
  indexedFarms: {} as IndexedFarms,
  bleManager: platformHasBle ? new BleManager() : null as BleManager,
  device: {} as Device,
  thermo: null as Device,
  deviceList: [] as Device[] | []
}

export type StateAction = 
  | ReturnType<typeof setIsConnected>
  | ReturnType<typeof setAccessToken>
  | ReturnType<typeof setRefreshToken>
  | ReturnType<typeof setEmail>
  | ReturnType<typeof setSaves>
  | ReturnType<typeof setDbUrl>
  | ReturnType<typeof setIndexedFarms>
  | ReturnType<typeof setDevice>
  | ReturnType<typeof setThermo>
  | ReturnType<typeof setDeviceList> 

const reducer: Reducer<StateProps, StateAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'setIsConnected':
      return { ...state, isConnected: action.value }
    case 'setAccessToken':
      return { ...state, accessToken: action.value }
    case 'setRefreshToken':
      return { ...state, refreshToken: action.value }
    case 'setEmail':
      return { ...state, email: action.value }
    case 'setSaves':
      return { ...state, saves: action.value }
    case 'setDbUrl':
      return { ...state, dbUrl: action.value }
    case 'setIndexedFarms':
      return { ...state, indexedFarms: action.value }
    case 'setDevice':
      return { ...state, device: action.value }
    case 'setThermo':
      return { ...state, thermo: action.value }
    case 'setDeviceList':
      return { ...state, deviceList: action.value }
    default:
      return state
  }
}

export type FunctionAction =
| ReturnType<typeof watchConnectivityChange>
| ReturnType<typeof updateTokens>
| ReturnType<typeof deleteSaveData>
| ReturnType<typeof farmsUpdated>
| ReturnType<typeof updateFarms>
| ReturnType<typeof pushOfflineCache>
| ReturnType<typeof checkBlePermissions>
| ReturnType<typeof scanAndListDevices>
| ReturnType<typeof connectToDevice>
| ReturnType<typeof scanAndConnect>
| ReturnType<typeof readAndCollect>
| ReturnType<typeof writeToDevice>
| ReturnType<typeof disconnectDevice>
| ReturnType<typeof grabFromThermo>

export type DispatchStateActions = 
  | StateAction
  | FunctionAction


const store = createStore(reducer, applyMiddleware(thunkMiddleware))

const setIsConnected = (isConnected: boolean): { type: 'setIsConnected', value: boolean } => {
  return {
    type: 'setIsConnected',
    value: isConnected
  }
}

const setAccessToken = (accessToken: string): { type: 'setAccessToken', value: string } => {
  return {
    type: 'setAccessToken',
    value: accessToken
  }
}

const setRefreshToken = (refreshToken: string): { type: 'setRefreshToken', value: string } => {
  return {
    type: 'setRefreshToken',
    value: refreshToken
  }
}

const setEmail = (email: string): { type: 'setEmail', value: string } => {
  return {
    type: 'setEmail',
    value: email
  }
}

const setSaves = (saves: number): { type: 'setSaves', value: number } => {
  return {
    type: 'setSaves',
    value: saves
  }
}

const setDbUrl = (dbUrl: string): { type: 'setDbUrl', value: string } => {
  return {
    type: 'setDbUrl',
    value: dbUrl
  }
}

const setIndexedFarms = (indexedFarms: IndexedFarms): { type: 'setIndexedFarms', value: IndexedFarms } => {
  return {
    type: 'setIndexedFarms',
    value: indexedFarms
  }
}

const setDevice = (device: Device): { type: 'setDevice', value: Device } => {
  return {
    type: 'setDevice',
    value: device
  }
}

const setThermo = (thermo: Device): { type: 'setThermo', value: Device } => {
  return {
    type: 'setThermo',
    value: thermo
  }
}

const setDeviceList = (deviceList: Device[]):{ type: 'setDeviceList', value: Device[] } => {
  return {
    type: 'setDeviceList',
    value: deviceList
  }
}

interface WatchConnectivityChangeAction {
  ReturnType: void
  State: StateProps
  BasicAction: {type: 'watchConnectivityChange'}
}

const watchConnectivityChange = () => {
  return function(dispatch: ThunkDispatch<StateProps, void, {type: 'setIsConnected'}>, getState) {
    NetInfo.addEventListener(async (state) => {
      let { isConnected } = getState()
      dispatch(setIsConnected(state.isConnected))
      if (state.isConnected !== isConnected && state.isConnected) {
        dispatch(pushOfflineCache())
      }
    })
  }
}

const updateTokens = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    let refreshToken = getState().refreshToken, email = getState().email
    let encoded = 'email=' + encodeURIComponent(email) + '&refreshToken=' + encodeURIComponent(refreshToken)
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: encoded
    }
    try {
      let res = await fetch(`${getState().dbUrl}/api/refreshToken`, options)
      let data = await res.text()
      let info = JSON.parse(data)
      if (info.auth) {
        let tokens = {
          accessToken: info.token as string,
          refreshToken: info.refreshToken as string
        }
        dispatch(setAccessToken(info.token))
        dispatch(setRefreshToken(info.refreshToken))
        await AsyncStorage.multiSet([['@mushrooms_accessToken', info.token], ['@mushrooms_refreshToken', info.refreshToken]])
        resolve(tokens)
      } else {
        reject(new Error('Authentication failed.'))
      }
    } catch (err) {
      reject(err)
    }
  })
  
}

const deleteSaveData = (id, type) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    let { accessToken } = getState()
    let body = { id }
    let options = {
      headers: {
        'X-Access-Token': accessToken,
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify(body)
    }
    let res = await fetch(`${getState().dbUrl}/api/${type}`, options).then(res => res.text())
    try{
      res = JSON.parse(res)
    } catch (err) {
      reject(new Error('Failed to delete. Server gave a bad response!'))
    }

    if (res.status === 'success') {
      dispatch(setSaves(getState().saves - 1))
      resolve()
    } else {
      reject(new Error('Failed to delete.'))
    }
  })

}

const farmsUpdated = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let localFarmVersions = await AsyncStorage.getItem('@mushrooms_farmVersions').then(val => JSON.parse(val))
      let options = {
        headers: {
          'X-Access-Token': getState().accessToken
        }
      }
      let farmVersions = await fetch(`${getState().dbUrl}/api/farmVersions`, options).then(res => res.json()).catch(err => reject(err))
      if (typeof farmVersions === 'object' && farmVersions.auth === false) {
        await dispatch(updateTokens())
        dispatch(farmsUpdated())
      }
      farmVersions = (Array.isArray(farmVersions) && farmVersions.length > 0) ? farmVersions[0].farms : []
      let indexedLocal = _.indexBy(localFarmVersions, 'id')
      let indexedExternal = _.indexBy(farmVersions, 'id')
      let match = _.isEqual(indexedLocal, indexedExternal)
      if (!match) {
        await AsyncStorage.multiSet([['@mushrooms_farmVersions', JSON.stringify(farmVersions)], ['@mushrooms_indexedFarmVersions', JSON.stringify(indexedExternal)]])
      }
      resolve(!match)
    } catch (err) {
      if (err.message === 'Authentication failed.') {
        reject(err)
      }
    }
  })
}

export type IndexedFarms = Awaited<ReturnType<ReturnType<typeof updateFarms>>>['indexedFarms']

const updateFarms = () => async (dispatch, getState) => {

    let indexedExternal: IndexedFarmVersions = await AsyncStorage.getItem('@mushrooms_indexedFarmVersions').then(val => JSON.parse(val))
    let options = {
      method: 'GET',
      headers: {
        'X-Access-Token': getState().accessToken as string
      }
    }
    let meRes: MeResponse = await fetch(`${getState().dbUrl}/api/me`, options).then(res => res.json())
    let userId = meRes.length > 0 ? meRes[0].userId : null
    let farms = meRes.length > 0 ? meRes[0].farms : []
    let farmsCopy = farms.map((farm) => {
      let indexedBuildings = _.indexBy(farm.buildings, 'id')
      let indexedMeasures = _.indexBy(farm.measureTypes, 'id')
      let indexedCropInputs = _.indexBy(farm.cropInputTypes, 'id')
      let indexedCropOutputs = _.indexBy(farm.cropOutputTypes, 'id')
      let indexedUOMs = _.indexBy(farm.cropInputUnitOfMeasures, 'cropInputUnitOfMeasureId')
      let indexedCropCycles = farm.crops.map((crop) => {
        return { ...crop, cropCycles: _.indexBy(crop.cropCycles, 'roomId') }
      })
      return { ...farm, isInit: !!indexedExternal ? indexedExternal[farm.id].isInit : false,buildings: indexedBuildings, measureTypes: indexedMeasures, cropInputTypes: indexedCropInputs, cropOutputTypes: indexedCropOutputs, cropInputUnitOfMeasures: indexedUOMs, crops: indexedCropCycles }
    })
    let indexedFarms = _.indexBy(farmsCopy, 'id')
    farms = farms.filter(farm => !!indexedExternal[farm.id] ? indexedExternal[farm.id].isInit : false)
    await AsyncStorage.multiSet([['@mushrooms_farms', JSON.stringify(farms)], ['@mushrooms_indexedFarms', JSON.stringify(indexedFarms)], ['@mushrooms_userId', JSON.stringify(userId)]])
    dispatch(setIndexedFarms(indexedFarms))
    return ({ farms, indexedFarms })
}

const pushOfflineCache = () => async (dispatch, getState) => {
  let { isConnected } = await NetInfo.fetch()
  let items = await AsyncStorage.multiGet(['@mushrooms_offlineCache', '@mushrooms_accessToken', '@mushrooms_dbUrl'])
  let offlineCache = JSON.parse(items[0][1])
  let accessToken = items[1][1]
  let dbUrl = items[2][1]
  /*
   * While loop checks whether offline cache contains data and device has connection.
   * This ensures that the entire collection of data is sent, even when POST fails for 'property'.
   */
  while (Object.values(offlineCache).flat(1).length > 0 && isConnected) {
    for (let property in offlineCache) {
      if (offlineCache[property].length === 0) {
        continue
      }
      let options = {
        method: 'POST',
        body: JSON.stringify({ [property]: offlineCache[property] }),
        headers: {
          'X-Access-Token': accessToken,
          'Content-Type': 'application/json'
        }
      }
      try {
        await fetch(`${dbUrl}/api/${property}`, options)
        .then(res => res.text())
        .then((data) => {
          return new Promise(async (resolve, reject) => {
              try {
                console.log(data)
                let info = JSON.parse(data)
                if (info.auth === false) {
                  await dispatch(updateTokens())
                }
                if (info.status === 'success') {
                  let deviceSaves = await AsyncStorage.getItem('@mushrooms_deviceSaves').then(val => JSON.parse(val))
                  deviceSaves = { ...deviceSaves, [property]: [...(deviceSaves[property] ? deviceSaves[property] : [])].concat(info.data ? info.data : offlineCache[property]) }
                  await AsyncStorage.setItem('@mushrooms_deviceSaves', JSON.stringify(deviceSaves))
                  offlineCache = { ...offlineCache, [property]: [] }
                  await AsyncStorage.setItem('@mushrooms_offlineCache', JSON.stringify(offlineCache))
                  await Notifications.scheduleNotificationAsync({
                    content: {
                      title: "Unsent data saved",
                      body: "The data you saved while offline has been sent."
                    },
                    trigger: null
                  })
                  dispatch(setSaves(getState().saves + 1))
                }
              } catch (err) {
                reject(err)
              } finally {
                resolve()
              }
            })
        })
      } catch (err) {
        
      }
    }
  }
}

const checkBlePermissions = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    if (Platform.OS === 'android' && Platform.Version >= 23) {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        console.log('Permission to access location was denied');
        resolve(false)
      }
      resolve(true)
    } else {
      resolve(true)
    }
  });
}

/**
 * Generate a list of devices.
 * @param {*} dispatch 
 * @param {*} getState 
 * @returns 
 */
const scanAndListDevices = () => (dispatch, getState) => {
  return new Promise<Device[]>(async (resolve, reject) => {

    /**@type {BleManager} */
    let bleManager: BleManager = getState().bleManager
    try {
      let state = await bleManager.state()
      if(state !== "PoweredOn") return
      let deviceList: Device[] = []
      bleManager.startDeviceScan(null, null, async (error, device) => {
        if (error) {
          console.warn("Error Reading BLE:", error)
          return
        }
        if (device) {
          const deviceAlreadyInList = deviceList.some(element => {
            if(element.id === device.id) 
              return true
          })
          if (deviceAlreadyInList) 
            return
          let isThermometer =
            (Platform.OS === 'android' || Platform.OS === 'ios') &&
            Buffer.from(String(device.manufacturerData), 'base64')
              .toString('utf-8')
              .includes('INTELLI_ROCKS')
          if (device.name === 'AgriPy' || device.name === 'raspberrypi' || isThermometer) {
            deviceList.push(device)
          }
        }
      })
      setTimeout(() => {
        bleManager.stopDeviceScan()
        resolve(deviceList)
      }, 8000)

    } catch (err) {
      reject(err)
    }
  });
};

/**
 * @param {import('react-native-ble-plx').Device} device 
 * @returns {Promise}
 */
const connectToDevice = (device: Device) => (dispatch, getState) => {
  return new Promise<Device>(async (resolve, reject): Promise<Device> => {
    /**@type {BleManager} */
    let bleManager: BleManager = getState().bleManager
    try {
      let state = await bleManager.state()
      if (state !== 'PoweredOn') {
        return
      }
      if(await device.isConnected())
        console.warn("Device is already connected.")
      else
        await device.connect()
      await device.discoverAllServicesAndCharacteristics()
      dispatch(setDevice(device))
      resolve(device)
    } catch (err) {
      reject(err)
    }
  })
}

const scanAndConnect = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    // /**@type {BleManager} */
    // let bleManager = getState().bleManager
    // let { device: { id } } = getState()
    // try {
    //   let state = await bleManager.state()
    //   if (state !== 'PoweredOn') {
    //     return
    //   }
    //   bleManager.startDeviceScan(null, null, async (error, device) => {
    //     if (error) {
    //       console.warn("Error Reading BLE:", error)
    //       return
    //     }
    //     if (device.name === 'AgriPy' || device.name === 'raspberrypi') {
    //       await device.connect()
    //       await device.discoverAllServicesAndCharacteristics()
    //       await device.readRSSI()
    //       let rssi
    //       if (id && device.id !== id) {
    //         try {
    //           await bleManager.connectToDevice(id)
    //           ({ rssi } = await bleManager.readRSSIForDevice(id))
    //         } catch (err) {
    //           console.error(err)
    //         }
    //         if (rssi && rssi > device.rssi) {
    //           await device.cancelConnection()
    //           device = await bleManager.discoverAllServicesAndCharacteristicsForDevice(id)
    //         }
    //       }
    //       bleManager.stopDeviceScan()
    //       dispatch(setDevice(device))
    //       resolve(device)
    //     }
    //   })
    // } catch (err) {
    //   reject(err)
    // }
  })
}

const readAndCollect = () => (dispatch, getState) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      let { bleManager, device } = getState()
      if (device.isConnectable && !await device.isConnected()) {
        await bleManager.connectToDevice(device.id)
        device = await bleManager.discoverAllServicesAndCharacteristicsForDevice(device.id)
      }
      let serviceUUID = 'fff0'
      let characteristicUUID = 'fff1'
      let characteristic = await device.readCharacteristicForService(serviceUUID, characteristicUUID)
      let data: string = Buffer.from(characteristic.value, 'base64').toString('utf8')
      console.log(data)
      resolve(data)
    } catch (err) {
      reject(err)
    }
  })
}

const writeToDevice = (location) => (dispatch, getState) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      /**@type {BleManager} */
      let bleManager: BleManager = getState().bleManager
      /**@type {import('react-native-ble-plx').Device} */
      let device: import('react-native-ble-plx').Device = getState().device
      while (!await device.isConnected()) {
        await bleManager.connectToDevice(device.id)
        device = await bleManager.discoverAllServicesAndCharacteristicsForDevice(device.id)
      }
      let serviceUUID = 'fff3'
      let characteristicUUID = 'fff4'
      let params = new URLSearchParams(location)
      let data = Buffer.from(params.toString(), 'utf8').toString('base64')
      await device.writeCharacteristicWithResponseForService(serviceUUID, characteristicUUID, data)
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

const disconnectDevice = (id?) => (dispatch, getState) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      let { bleManager, device } = getState()
      id = id || device.id
      if (await bleManager.isDeviceConnected(id)) {
        await bleManager.cancelDeviceConnection(id)
      }
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

const asyncTimeout = (ms = 9000) => {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}

interface ThermoRecord {
  doubleMeasure: number,
  recordedAt: string,
  measureTypeName: string,
  id: string
}

const grabFromThermo = (_thermo?: Device) => (dispatch, getState) => {
  if(Platform.OS === 'android') {
    const { BLEModule } = NativeModules
    const timeout = asyncTimeout(9000)
    const getter = new Promise<ThermoRecord>(async (resolve, reject) => {
      try {
        let thermo: Device = getState().thermo as Device
        if (_thermo instanceof Device) thermo = _thermo
        await BLEModule.setAddress(thermo.id)
        BLEModule.scanAndGrabFromThermo(
          (err: string, thermoData: number) => {
            if (!err) {
              console.log({ thermoData })
              
              let records: ThermoRecord = { 
                doubleMeasure: thermoData, 
                recordedAt: moment().format(), 
                measureTypeName: 'temperature',  
                id: uuid()
              }
              resolve(records)
              BLEModule.stopScanLeDevice()
            }
            else
              console.error(err)
          }
        )
        setTimeout(() => { 
          BLEModule.stopScanLeDevice() 
        }, 15000)
        
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
    return Promise.race([timeout, getter])
  } else {
    return new Promise<ThermoRecord>(async (resolve, reject) => {
      let thermo: Device = getState().thermo as Device
      if (_thermo instanceof Device) thermo = _thermo
      //let timeoutPromise = asyncTimeout(15000)
      let updated = false
      const devices = await dispatch(scanAndListDevices())
      for (let d of devices) {
        if(d.id === thermo.id) {
          thermo = d
          updated = true
        }
      }
      if(!updated) {
        reject()
        return
      }
      const rawData = Buffer
        .from(String(thermo.manufacturerData), 'base64')
      const rawDataInHex = rawData.toString('hex')
      const tempInHex = rawDataInHex.substring(20, 24)
      const tempTimes100 = parseInt(`0x${tempInHex}`)
      if (tempTimes100 === NaN) {
        reject() // should never happen because the value is a parsed hex.
      }
      const temp = tempTimes100 / 100
      let records: ThermoRecord = {
        doubleMeasure: temp,
        recordedAt: moment().format(),
        measureTypeName: 'temperature',
        id: uuid()
      }
      resolve(records)
    })
  }
}

export {
  store,
  setIsConnected,
  setAccessToken,
  setRefreshToken,
  setEmail,
  setSaves,
  setDbUrl,
  setIndexedFarms,
  setDevice,
  setThermo,
  setDeviceList,
  watchConnectivityChange,
  updateTokens,
  deleteSaveData,
  farmsUpdated,
  updateFarms,
  pushOfflineCache,
  checkBlePermissions,
  scanAndListDevices,
  connectToDevice,
  scanAndConnect,
  readAndCollect,
  writeToDevice,
  disconnectDevice,
  grabFromThermo
}
