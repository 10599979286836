import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

const setItem = (key: string, value: string) => {
  return new Promise<void>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    if (mobile) {
      await AsyncStorage.setItem(key, value)
    } else {
      window.localStorage.setItem(key, value)
    }
    resolve()
  })
}

const getItem = (key: string) => {
  return new Promise<string>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    let item: string
    if (mobile) {
      item = await AsyncStorage.getItem(key)
    } else {
      item = window.localStorage.getItem(key)
    }
    resolve(item)
  })
}

const multiSet = (items) => {
  return new Promise<void>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    if (mobile) {
      await AsyncStorage.multiSet(items)
    } else {
      items.forEach(item => window.localStorage.setItem(item[0], item[1]))
    }
    resolve()
  })
}

const multiGet = (keys: string[]) => {
  return new Promise<[string, string][]>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    let items: [string, string][]
    if (mobile) {
      items = await AsyncStorage.multiGet(keys)
    } else {
      items = keys.map(key => [key, window.localStorage.getItem(key)])
    }
    resolve(items)
  })
}

const clear = () => {
  return new Promise<void>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    if (mobile) {
      await AsyncStorage.clear()
    } else {
      window.localStorage.clear()
    }
    resolve()
  })
}

const removeItem = (key: string, value: string) => {
  return new Promise<void>(async (resolve, reject) => {
    let mobile = Platform.OS === 'ios' || Platform.OS === 'android'
    if (mobile) {
      await AsyncStorage.removeItem(key)
    } else {
      window.localStorage.removeItem(key)
    }
    resolve()
  })
}

export default {
  setItem,
  getItem,
  multiSet,
  multiGet,
  clear,
  removeItem
}
