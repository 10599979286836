import React, { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Pressable } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'

const FarmCard = (props) => {
  const [pressed, setPressed] = useState(false)
  return (
    <Pressable
      style={styles.card}
      onPressIn={() => setPressed(!pressed)}
      onPressOut={() => setPressed(!pressed)}
      onPress={() => props.navigate(props.farm.id)}
    >
      <View>
        <Text style={[styles.cardTitle, pressed && { textDecorationLine: 'underline' }]}>{props.farm.name}</Text>
        <View style={styles.chipContainer}>
          <TouchableOpacity style={styles.chip} onPress={() => props.navigate(props.farm.id)}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.chipText}>View Buildings</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {
        pressed &&
          <View style={{ marginTop: 8 }}>
            <MaterialCommunityIcons name="chevron-right" size={24} />
          </View>
      }
    </Pressable>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 12,
    shadowColor: 'black',
    shadowOpacity: 0.25,
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginBottom: 24,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  chip: {
    height: 30,
    minWidth: 96,
    backgroundColor: '#d5d5d5',
    paddingHorizontal: 8,
    borderRadius: 15,
    marginRight: 12,
    marginTop: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  chipText: {
    fontSize: 14,
    fontFamily: 'Roboto Regular'
  },
  chipContainer: {
    flexDirection: 'row'
  },
  cardTitle: {
    fontSize: 20,
    fontFamily: 'Roboto Medium',
    marginVertical: 8
  }
})

export default FarmCard
