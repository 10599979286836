import React, { useState, useCallback } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import { useNavigation, useRoute, useFocusEffect } from '@react-navigation/native'

interface Props {
  items: string[]
}

const Breadcrumbs = (props: Props) => {
  const [items, setItems] = useState(props.items)
  const [pressed, setPressed] = useState('')
  const navigation = useNavigation()
  const route = useRoute()
  const { name } = route
  useFocusEffect(
    useCallback(() => {
      setItems(props.items.slice(0, props.items.indexOf(name) + 1))
      setPressed(name)
    }, [name])
  )
  const onScreenPress = (screen: string) => {
    setPressed(screen)
    if (screen == 'Buildings' && name == 'Rooms')
    {
      navigation.goBack();
    }
    else if(screen == 'Buildings' && name == 'Sections')
    {
      navigation.goBack(navigation.goBack());
    }
    else if(screen == 'Buildings' && name == 'SectionsAlt')
    {
      navigation.goBack(navigation.goBack(navigation.goBack()));
    }
    else if (screen == 'Rooms' && name == 'Sections')
    { 
      navigation.goBack();
    }
    else if (screen == 'Rooms' && name == 'SectionsAlt')
    {
      navigation.goBack(navigation.goBack());
    }
    else if (screen == 'Sections' && name == 'SectionsAlt')
    {
      navigation.goBack();
    }
    else
    {
    navigation.navigate(screen);
    }
  }
  return (
    <View style={styles.breadcrumbs}>
      {
        items.map((item, index) => (
          <Pressable key={item} onPress={() => onScreenPress(item)}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={[
                styles.breadcrumb,
                pressed === item &&
                  {
                    fontFamily: 'Roboto Medium',
                    textDecorationLine: 'underline'
                  }
              ]}>{item}</Text>
              <Text>{index !== items.length - 1 && '  /  '}</Text>
            </View>
          </Pressable>
        ))
      }
    </View>
  )
}

const styles = StyleSheet.create({
  breadcrumbs: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginVertical: 19
  },
  breadcrumb: {
    fontFamily: 'Roboto Regular',
    fontSize: 14,
    color: '#43425D'
  }
})

export default Breadcrumbs