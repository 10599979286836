import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import { Button } from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native'
import AsyncStorage from '../AsyncStorage'
import { Square } from '../type/api/farms'
import { SectionsScreenAltProps as NavigationProps } from '../App'

/**
 * @typedef SectionCardProps
 * @type {object}
 * @property {object} section
 * @property {string} recordType 'measure' or 'crop input'
 * @property {function():string|number} getSelectedMeasureTypeId measureTypeId or cropInputTypeId
 * @property {function():void} setSelectedMeasureTypeId React setter from 
 */

/**
 * @param {SectionCardProps} props 
 * @returns 
 */

type SectionCardOwnProps = {
  section: Square
  recordType: string
}

type SectionCardProps = SectionCardOwnProps & NavigationProps['route']

const SectionCard = (props: SectionCardProps) => {
  const navigation = useNavigation()
  const route = useRoute<NavigationProps['route']>()
  return (
    <TouchableOpacity style={
      /*@ts-expect-error*/
      StyleSheet.compose(styles.card, {backgroundColor: (route.params.sectionId == props.section.id) ? '#43425D' : 'white'})      
    } onPress={() => {
      AsyncStorage.getItem('@mushrooms_selectedMIOTypeId')
      .then(async MIOTypeId => { 
        return [await AsyncStorage.getItem('@mushrooms_thermoScan'), MIOTypeId] })
      .then((data) =>
        navigation.setParams({ 
            sectionId: props.section.id, 
            add: props.recordType,
            thermoScan: JSON.parse(data[0]),
            MIOTypeId: data[1]
          })
        )
      }
    }
    >
      <Text style={
        /*@ts-expect-error*/
        StyleSheet.compose(styles.cardTitle, {color: route.params.sectionId == props.section.id ? 'white' : 'black'})
        }>{props.section.name}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 12,
    shadowColor: 'black',
    shadowOpacity: 0.25,
    backgroundColor: 'white' ,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginBottom: 24,
    height: 70,
    minWidth: 90,
    margin: 6
  },
  cardTitle: {
    fontSize: 20,
    fontFamily: 'Roboto Medium',
    marginVertical: 8,
    marginHorizontal: 8,
    color: 'white'
  },
})

export default SectionCard