import DateTimePicker from '@react-native-community/datetimepicker'
import { useKeyboard } from '@react-native-community/hooks'
import { useNavigation, useRoute } from '@react-navigation/native'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Animated, GestureResponderEvent, NativeModules, Platform, Pressable, StyleSheet, TextInput, View } from 'react-native'
import { ActivityIndicator, Button, Dialog, IconButton, Snackbar } from 'react-native-paper'
import { connect, ConnectedProps } from 'react-redux'
import uuid from 'uuid/v4'
import { Dropdown, TagEntry } from '.'
import AsyncStorage from '../AsyncStorage'
import AuthContext from '../AuthContext'
import { mapDispatchToProps, mapStateToProps } from '../redux/mapping'
import { Room } from '../type/api/farms'
import { SectionsScreenAltProps as NavigationProps } from '../App'
import { Buffer } from 'buffer'

const connector = connect(mapStateToProps, mapDispatchToProps)
type AddFormProps = ConnectedProps<typeof connector>

const AddForm = (props: AddFormProps) => {
  const [tags, setTags] = useState([])
  const [value, setValue] = useState('')
  const [pickerValue, setPickerValue] = useState('')
  const [unit, setUnit] = useState('')
  const [date, setDate] = useState(moment().format('MM/DD/YY'))
  const [time, setTime] = useState(moment().format('HH:mm'))
  const route = useRoute<NavigationProps['route']>()
  const { add, roomId, sectionId } = route.params || {}
  const [farmId, setFarmId] = useState(route.params?.farmId)
  const [scanEnabled, setScanLoopPressed] = useState(false)
  const { indexedFarms } = props
  const authContext = useContext(AuthContext)
  useEffect(() => {
    setDate(moment().format('MM/DD/YY'))
    setTime(moment().format('HH:mm'))
    setPickerValue('')
    setValue('')
    setUnit('')
    setTags([])
  }, [add])
  const locateRoom = (id: string) => {
    return new Promise<Room>(async (resolve) => {
      let result: Room
      Object.values(indexedFarms).forEach((farm) => {
        Object.values(farm.buildings).forEach((building) => {
          building.rooms.forEach((room) => {
            if (room.id === id) {
              result = room
            }
          })
        })
      })
      resolve(result)
    })
  }
  useEffect(() => {
    let { farmId } = route.params || {}
    if (!farmId) {
      if (roomId) {
        locateRoom(roomId)
          .then(room => setFarmId(String(room.farmId)))
      }
    } else {
      setFarmId(farmId)
    }
  }, [roomId])
  const onPickerChange = (value: number | React.SetStateAction<string>) => {
    let { measureTypes, cropInputTypes, cropOutputTypes, cropInputUnitOfMeasures } = indexedFarms[farmId]
    if (value) {
      switch (add) {
        case 'measure':
          setUnit(measureTypes[value]['unitOfMeasure'])
          break
        case 'crop input':
          setUnit(cropInputUnitOfMeasures[cropInputTypes[value]['unitOfMeasureId']]['unitOfMeasure'])
          break
        case 'crop output':
          setUnit(cropInputUnitOfMeasures[cropOutputTypes[value]['unitOfMeasureId']]['unitOfMeasure'])
      }
    } else {
      setUnit('')
    }
    setPickerValue(value)
    setValue('')
  }
  const [snackbarVisible, setSnackbarVisible] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [invalidTimeAnim] = useState(new Animated.Value(0))
  const [invalidDateAnim] = useState(new Animated.Value(0))
  const [timePressed, setTimePressed] = useState(false)
  const [datePressed, setDatePressed] = useState(false)
  const isValid = (dateOrTime: 'date' | 'time') => {
    let datetime: string
    let comparator = [] as string[]
    let anim: Animated.Value
    switch (dateOrTime) {
      case 'date':
        datetime = date
        comparator = ['MM/DD/YY', 'M/D/YY', 'MM/DD/YYYY', 'M/D/YYYY']
        anim = invalidDateAnim
        break
      case 'time':
        datetime = time
        comparator = ['HH:mm','h:mm','h:mm','hmm','HHmm']
        anim = invalidTimeAnim
    }
    let toValue: number, isValid: boolean
    if (moment(datetime, comparator, true).isValid()) {
      toValue = 0, isValid = true
    } else {
      toValue = 1, isValid = false
    }
    Animated.timing(anim, { toValue, duration: 200, useNativeDriver: false }).start()
    return isValid
  }
  const [lastItem, setLastItem] = useState({})
  const onSubmit = async () => {
    type onSubmitBody = {
      roomId: string
      id: string
      squareId?: string
      measureTypeId?: string
      cropInputTypeId?: string
      cropOutputTypeId?: string
      intCount?: number
      booleanValue?: string | true
      doubleMeasure?: number
      recordedAt?: string
      tags?: any
    }
    let body: onSubmitBody = { roomId, id: uuid() }
    if (sectionId) 
    {
      body.squareId = sectionId
    }
    else
    {
      setSnackbarMessage('Cannot save with empty section.')
      setSnackbarVisible(true)
      return
    }
    switch (add) {
      case 'measure':
        body.measureTypeId = pickerValue
        break
      case 'crop input':
        body.cropInputTypeId = pickerValue
        break
      case 'crop output':
        body.cropOutputTypeId = pickerValue
    }
    switch (unit) {
      case 'Count':
        body.intCount = parseInt(value)
        break
      case 'Presence/Absence':
        body.booleanValue = value === '' || value
        break
      default:
        body.doubleMeasure = parseFloat(value)
    }
    if (isValid('date') && isValid('time')) {
      body.recordedAt = moment(date + ' ' + time).format()
    } else {
      setSnackbarMessage('Cannot save with invalid date or time.')
      setSnackbarVisible(true)
      return
    }
    let mappedTags = {}
    for (let i = 0; i < tags.length; i++) {
      if (!mappedTags.hasOwnProperty(tags[i].name)) {
        if (!tags[i].name || !tags[i].value) {
          setSnackbarMessage('Cannot save with empty tagnames or values.')
          setSnackbarVisible(true)
          return
        } else {
          mappedTags[tags[i].name] = tags[i].value
        }
      } else {
        setSnackbarMessage('Cannot save with duplicate tagnames.')
        setSnackbarVisible(true)
        return
      }
    }
    body.tags = mappedTags
    if (value === '' && unit !== 'Presence/Absence') {
      setSnackbarMessage('Cannot save with empty value.')
      setSnackbarVisible(true)
      return
    }
    const handleErrors = async (res: { ok: any }) => {
      if (!res.ok) {
        let offlineCache = await AsyncStorage.getItem('@mushrooms_offlineCache').then(val => JSON.parse(val))
        let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
        offlineCache = { ...offlineCache, [property]: [...(offlineCache[property] ? offlineCache[property] : []), body] }
        await AsyncStorage.setItem('@mushrooms_offlineCache', JSON.stringify(offlineCache))
        setSnackbarMessage('Error encountered. Saved locally.')
        setSnackbarVisible(true)
        return
      }
      return res
    }
    if (props.isConnected) {
      let options = {
        headers: {
          'X-Access-Token': props.accessToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body.measureTypeId ? { records: [body] } : body.cropInputTypeId ? { cropInputs: [body] } : { cropOutputs: [body] })
      }
      fetch(`${props.dbUrl}/api/${body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'}`, options)
        .then(handleErrors)
        .then(res => res.text())
        .then(async data => {
          console.log(data)
          try {
            let info = JSON.parse(data)
            if (info.auth === false) {
              await props.updateTokens()
              onSubmit()
            }
            if (info.status === 'success') {
              let deviceSaves = await AsyncStorage.getItem('@mushrooms_deviceSaves').then(val => JSON.parse(val))
              let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
              deviceSaves = { ...deviceSaves, [property]: [...(deviceSaves[property] ? deviceSaves[property] : []), info.data[0] ]}
              await AsyncStorage.setItem('@mushrooms_deviceSaves', JSON.stringify(deviceSaves))
              setSnackbarMessage('Sent successfully.')
              setSnackbarVisible(true)
              props.setSaves(props.saves + 1)
              setLastItem({ ...info.data[0], type: property })
            }
          } catch(err) {
            console.error(err)
            if (err.message === 'Authentication failed.') {
              authContext.setIsSignedIn(false)
              authContext.setIsLoaded(false)
            }
            setSnackbarMessage('Failed to send.')
            setSnackbarVisible(true)
          }
        })
    } else {
      let offlineCache = await AsyncStorage.getItem('@mushrooms_offlineCache').then(val => JSON.parse(val))
      let property = body.measureTypeId ? 'records' : body.cropInputTypeId ? 'cropInputs' : 'cropOutputs'
      offlineCache = { ...offlineCache, [property]: [...(offlineCache[property] ? offlineCache[property] : []), body] }
      await AsyncStorage.setItem('@mushrooms_offlineCache', JSON.stringify(offlineCache))
      setSnackbarMessage('Saved locally.')
      setSnackbarVisible(true)
      setLastItem({ ...body, type: property })
    }
  }
  const onUndoPress = async () => {
    try {
      let location: string
      if (lastItem.id.split('-').length === 5) {
        location = '@mushrooms_offlineCache'
      } else {
        location = '@mushrooms_deviceSaves'
        await props.deleteSaveData(lastItem.id, lastItem.type)
      }
      let data = await AsyncStorage.getItem(location).then(val => JSON.parse(val))
      await AsyncStorage.setItem(
        location,
        JSON.stringify({
          ...data,
          [lastItem.type]: data[lastItem.type].filter((item: { id: any }) => item.id !== lastItem.id)
        })
      )
      setSnackbarMessage('Deleted successfully.')
      setSnackbarVisible(true)
    } catch (err) {
      console.error(err)
    }
  }

  const asyncTimeout = (ms = 9000) => {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }

  return (
  <View>
      <Dialog.Content>
        <Dropdown
          value={pickerValue}
          onChange={onPickerChange}
          placeholder={`Select ${add} type...`}
          items={farmId &&
            add === 'measure' ?
              Object.values(indexedFarms[farmId]['measureTypes']).filter((measureType) => measureType.inUse && measureType.measureSpaceType === 'Section').map((measureType) => {
                return { label: measureType.measureTypeName, value: measureType.id }
              }) : add === 'crop input' ?
              Object.values(indexedFarms[farmId]['cropInputTypes']).filter((cropInputType) => cropInputType.inUse && cropInputType.cropInputSpaceType === 'Section').map((cropInputType) => {
                return { label: cropInputType.cropInputTypeName, value: cropInputType.id }
              }) : []
          }
        />
        {
          unit === 'Presence/Absence' &&
            <Dropdown
              value={value === '' || value}
              onChange={setValue}
              style={{ marginVertical: 0, marginBottom: 12 }}
              items={[
                {
                  label: 'Presence',
                  value: true
                },
                {
                  label: 'Absence',
                  value: false
                }
              ]}
            />
        }
        {
          (unit !== 'Presence/Absence' && unit !== '') &&
          <View style={{ flexDirection: 'row' }}>
            <TextInput
              value={value}
              style={[styles.textInput, { flex: 8, marginBottom: 12 }]}
              keyboardType="numeric"
              placeholder={unit}
              onChangeText={setValue}
              maxLength={8}
              returnKeyType="done"
            />
          </View>
        }
        <View style={[{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 12 }]}>
          {
            Platform.OS !== 'ios' &&
              <View style={{ flex: 1, borderRadius: 4, overflow: 'hidden', alignItems: 'center' }}>
                <TextInput
                  value={time}
                  style={[styles.textInput, { height: 43, marginBottom: 0 }]}
                  placeholder={'HH:MM'}
                  showSoftInputOnFocus={false} //hide keyboard on focus
                  onChangeText={setTime}
                  onSubmitEditing={() => isValid('time')}
                  onTouchEnd={() => setTimePressed(true)}
                  maxLength={5}
                  returnKeyType="done"
                />
                <View style={{ position: 'absolute', top: 0, right: 0 }}>
                  <IconButton icon="clock-outline" size={20} color={'rgb(107, 107, 107)'} onPress={Platform.OS === 'android' && props.handleTimeTap} />
                </View>
                <Animated.View style={{ 
                  width: invalidTimeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0%', '100%']
                  }),
                  height: 3,
                  bottom: 0,
                  position: 'absolute',
                  backgroundColor: 'red'
                }}></Animated.View>
              </View>
          }
          {
            (Platform.OS === 'ios' || timePressed) &&
              <DateTimePicker 
                mode="time" 
                style={{ zIndex: 999 }}
                value={moment(time, ['HH:mm','h:mm','h:mm','hmm','HHmm'], true).toDate() || moment().toDate()} 
                onChange={(e, result) => {
                  setTimePressed(false)
                  setTime(moment(result).format('HH:mm'))
                }}

              />
          }
          <View style={{ width: 24 }}></View>
          {
            Platform.OS !== 'ios' &&
              <View style={{ flex: 1, borderRadius: 4, overflow: 'hidden', alignItems: 'center' }}>
                <TextInput
                  value={date}
                  style={[styles.textInput, { height: 43, marginBottom: 0 }]}
                  placeholder={'MM/DD/YY'}
                  showSoftInputOnFocus={false} //hide keyboard on focus
                  onChangeText={setDate}
                  onSubmitEditing={() => isValid('date')}
                  onTouchEnd={() => setDatePressed(true)}
                  maxLength={8}
                  returnKeyType="done"
                />
                <View style={{ position: 'absolute', top: 0, right: 0 }}>
                  <IconButton icon="calendar" size={20} color={'rgb(107, 107, 107)'} onPress={Platform.OS === 'android' && props.handleDateTap} />
                </View>
                <Animated.View style={{ 
                  width: invalidDateAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0%', '100%']
                  }),
                  backgroundColor: 'red',
                  height: 3,
                  bottom: 0,
                  position: 'absolute'
                }}></Animated.View>
              </View>
          }
          {
            (Platform.OS === 'ios' || datePressed) &&
              <DateTimePicker 
                mode="date" 
                style={{ zIndex: 999 }}
                value={moment(date, ['MM/DD/YYYY','MM/DD/YY','M/D/YYYY','M/D/YY'], true).toDate() || moment().toDate()}
                onChange={(e, result) => {
                  setDatePressed(false)
                  setDate(moment(result).format('MM/DD/YY'))
                }}
              />
          }
        </View>
        {
          !sectionId &&
            <TagEntry tags={tags} handleTags={setTags} />
        }
        <Button 
          style={{ paddingHorizontal: 12 }}
          mode="contained" 
          onPress={onSubmit}>Save</Button>
      </Dialog.Content>
    <Snackbar 
      visible={snackbarVisible}
      duration={3000}
      action={(snackbarMessage === 'Sent successfully.' || snackbarMessage === 'Saved locally.') && {
        label: 'Undo',
        onPress: onUndoPress
      }}
      onDismiss={() => setSnackbarVisible(false)}
    >
    {snackbarMessage}
    </Snackbar>
    </View>
  )
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddForm)
