import React, { useState, useEffect } from 'react'
import { FlatList, View } from 'react-native'
import { DataViewCard, InfoCard } from '../components'
import AsyncStorage from '../AsyncStorage'
import _ from 'underscore'
import moment from 'moment'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'

const DataView = (props) => {
  const [data, setData] = useState([])
  const [rooms, setRooms] = useState({})
  const [sections, setSections] = useState({})
  const [cached, setCached] = useState(false)
  const [numCached, setNumCached] = useState(0)
  const { indexedFarms, isConnected, deleteSaveData, saves } = props
  const loadAsync = async () => {
    let data = await AsyncStorage.getItem('@mushrooms_deviceSaves').then(val => JSON.parse(val))
    let offlineCache = await AsyncStorage.getItem('@mushrooms_offlineCache').then(val => JSON.parse(val))
    let cached = false
    for (let property in offlineCache) {
      if (offlineCache[property].length > 0) {
        data[property] = [...offlineCache[property], ...(data[property] || [])]
        cached = true
        setNumCached(offlineCache[property].length)
      }
    }
    setCached(cached)
    let values = []
    let measureTypes = {}, cropInputTypes = {}, cropOutputTypes = {}
    for (let id in indexedFarms) {
      measureTypes = { ...measureTypes, ...indexedFarms[id]['measureTypes'] }
      cropInputTypes = { ...cropInputTypes, ...indexedFarms[id]['cropInputTypes'] }
      cropOutputTypes = { ...cropOutputTypes, ...indexedFarms[id]['cropOutputTypes'] }
    }
    let sections = {}, rooms = {}
    for (let type in data) {
      data[type].forEach(value => {
        switch (type) {
          case 'records':
            value.typeName = measureTypes[value.measureTypeId]['measureTypeName']
            break
          case 'cropInputs':
            value.typeName = cropInputTypes[value.cropInputTypeId]['cropInputTypeName']
            break
          case 'cropOutputs':
            value.typeName = cropOutputTypes[value.cropOutputTypeId]['cropOutputTypeName']
        }
        if (value.squareId) sections[value.squareId] = {}
        rooms[value.roomId] = {}
        value.type = type
        values.push(value)
      })
    }
    values.sort((a, b) => moment(b.recordedAt).diff(a.recordedAt))
    setData(values)
    Object.values(indexedFarms).forEach(farm => {
      Object.values(farm.buildings).forEach(building => {
        building.rooms.forEach(room => {
          if (rooms[room.id]) {
            rooms[room.id] = {
              name: room.name,
              farmId: farm.id,
              buildingId: building.id
            }
          }
          room.rows.forEach(row => {
            row.levels.forEach(level => {
              level.squares.forEach(square => {
                if (sections[square.id]) sections[square.id] = square
              })
            })
          })
        })
      })
    })
    setRooms(rooms)
    setSections(sections)
  }
  useEffect(() => {
    loadAsync()
  }, [isConnected, saves])
  const deleteData = async (value) => {
    let location
    try {
      if (value.id.split('-').length === 5) {
        location = '@mushrooms_offlineCache'
      } else {
        if (!isConnected) {
          throw new Error('Cannot delete sent data while offline.')
        }
        location = '@mushrooms_deviceSaves'
        deleteSaveData(value.id, value.type)
      }
    } catch (err) {
      props.handleSnackbarVisible(err.message)
      return
    }
    let data = await AsyncStorage.getItem(location).then(val => JSON.parse(val))
    await AsyncStorage.setItem(
      location,
      JSON.stringify({
        ...data,
        [value.type]: data[value.type].filter(item => item.id !== value.id)
      })
    )
    props.handleSnackbarVisible('Deleted successfully.')
    loadAsync()
  }
  const renderItem = ({ item }) => (
    <DataViewCard 
      value={item}
      rooms={rooms}
      sections={sections}
      handleDeleteData={deleteData}
    />
  )
  let listProps = {}
  if (data.length > 0) {
    listProps = {
      ListHeaderComponent: <View style={{height: 6}}></View>,
      ListFooterComponent: <View style={{height: 6}}></View>
    }
  }
  if (cached) {
    listProps.ListHeaderComponent = <InfoCard iconName="content-save-alert-outline" text={`${numCached} items were saved locally. Reconnect to send them.`} style={{ marginHorizontal: 24, marginVertical: 12 }} />
  }
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={item => item.id}
      {...listProps}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView)
