import React, { useState, useEffect, useCallback } from 'react'
import { SafeAreaView, View, ActivityIndicator, StyleSheet, FlatList, ScrollView } from 'react-native'
import { SearchField, InfoCard, AddModal, Header, AlertDetailView, RoomCard } from './components'
import _ from 'underscore'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect } from 'react-redux'
import AsyncStorage from './AsyncStorage'
import { useFocusEffect } from '@react-navigation/native'
import { useMediaQuery } from 'react-responsive'
import { useRoute } from '@react-navigation/core'

const RoomsScreen = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [rooms, setRooms] = useState([])
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const { farmId, buildingId } = props.route.params || {}
  const { indexedFarms } = props
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 767.98 })
  const loadAsync = async () => {
    setQuery('')
    let rooms = []
    if (farmId) {
      if (buildingId) {
        rooms = indexedFarms[farmId]['buildings'][buildingId]['rooms']
      } else {
        Object.values(indexedFarms[farmId]['buildings']).forEach(b => rooms.push(...b.rooms))
      }
    } else {
      let farms = await AsyncStorage.getItem('@mushrooms_farms').then(val => JSON.parse(val))
      farms.forEach(farm => farm.buildings.forEach(building => rooms.push(...building.rooms)))
    }
    rooms.sort((a, b) => a.name.localeCompare(b.name))
    console.log(rooms.map(room => room.name))
    setIsLoaded(true)
    setRooms(rooms)
  }
  useEffect(() => {
    if (!isLoaded) {
      loadAsync()
    }
  }, [isLoaded])
  useFocusEffect(
    useCallback(() => {
      if (isLoaded) {
        loadAsync()
      }
    }, [farmId, buildingId])
  )
  useEffect(() => {
    setResults(
      rooms.filter(room => {
        return room.name.toLowerCase().includes(query.toLowerCase())
      })
    )
  }, [query])
  const navigate = (roomId, buildingId, farmId, recordType) => {
    props.navigation.navigate('Sections', { roomId, buildingId, farmId, recordType})
  }
  const renderItem = ({ item }) => (
    <RoomCard
      room={item}
      navigate={navigate}
    />
  )
  let listProps = {
    renderItem: renderItem,
    keyExtractor: item => item.id,
    style: styles.cardScroll
  }
  if (!isMobileDevice) {
    listProps.columnWrapperStyle = { justifyContent: 'space-between' }
  }
  if (rooms.length === 0 && !isLoaded) {
    listProps.ListHeaderComponent = <ActivityIndicator size="small" color="#222e3d" />
  } else if (rooms.length === 0 && results.length === 0 && isLoaded) {
    listProps.ListHeaderComponent = <InfoCard iconName="alert-outline" text="Looks like there are no sections in this room." buttonText="Go Back" onButtonPress={() => navigation.goBack()} />
  }
  return (
    <SafeAreaView edges={['right', 'bottom', 'left']} style={styles.container}>
      <View style={styles.viewPadding}/>
      <SearchField handleTextChange={setQuery} value={query} placeholder={`Search ${(indexedFarms[farmId] && buildingId) ? `${indexedFarms[farmId]['buildings'][buildingId]['name']}'s ` : ''}Rooms`} />
      {/* <ScrollView keyboardDismissMode="interactive" persistentScrollbar={true} contentContainerStyle={styles.cardScroll}>
      </ScrollView> */}
      {
        rooms.length === 0 && !isLoaded &&
          <ActivityIndicator size="small" color="#222e3d" />
      }
      {
        rooms.length === 0 && isLoaded &&
          <InfoCard iconName="alert-outline" text="Looks like there are no rooms in this building." buttonText="Go Back" onButtonPress={() => navigation.goBack()} />
      }
      {
        results.length > 0 ? 
          <FlatList
            data={results} 
            numColumns={!isMobileDevice ? 2 : 1}
            {...listProps}
          /> : results.length === 0 && query ?
          <InfoCard text="Your search did not produce any results." iconName="alert-outline" style={{marginHorizontal: 24, marginVertical: 12}} /> :
          <FlatList
            data={rooms}
            numColumns={!isMobileDevice ? 2 : 1}
            {...listProps}
          />
      }
      <AlertDetailView />
      <AddModal />
    </SafeAreaView >
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FAFAFA',
    flex: 1
  },
  viewPadding: {
    paddingTop: 12
  },
  cardScroll: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    flexGrow: 1
  },
  farmOptions: {
    justifyContent: 'flex-end'
  },
  farmActions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
    marginHorizontal: 12,
  },
  actionButtonText1: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#43425D',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  actionButtonText2: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: 'white'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RoomsScreen)
