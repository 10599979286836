import React from 'react'
import { StyleSheet, Animated, Easing, TextInput } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { TouchableRipple } from 'react-native-paper'

export default class TagEntryFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      textEntryAnim: new Animated.Value(0)
    }
  }
  componentDidMount() {
    Animated.timing(
      this.state.textEntryAnim,
      {
        toValue: 1,
        duration: 200,
        easing: Easing.bezier(0.39, 0.575, 0.565, 1)
      }
    ).start()
  }
  render() {
    return (
      <Animated.View style={{ flexDirection: 'row', opacity: this.state.textEntryAnim, transform: [{
        translateY: this.state.textEntryAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [-55, 0]
        })
      }] }}>
        <TextInput
          placeholder={'Tagname'}
          value={this.props.tagEntryName}
          onChangeText={(value) => this.props.handleTagEntry(value, this.props.index, 'name')}
          style={[styles.textInput, { flex: 1, marginRight: 12, marginBottom: 12 }]}
          returnKeyType="done"
        />
        <TextInput
          value={this.props.tagEntryValue}
          onChangeText={(value) => this.props.handleTagEntry(value, this.props.index, 'value')}
          placeholder={'Value'}
          returnKeyType="done"
          style={[styles.textInput, { flex: 1, marginRight: 12, marginBottom: 12 }]}
        />
        <TouchableRipple style={styles.iconButton} onPress={this.props.handleDelete}>
          <MaterialCommunityIcons name={'delete'} size={20} color={'#43425D'} />
        </TouchableRipple>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginBottom: 24
  },
  iconButton: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 4,
    height: 43,
    width: 43,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
