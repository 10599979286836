import React, { PureComponent } from 'react'
import { View, SafeAreaView, Platform } from 'react-native'
import { Drawer, Text } from 'react-native-paper'
import AsyncStorage from '../AsyncStorage'
import { expo } from '../app.json'

const checkIfBLEAvailable = () => {
  try {
    new BleManager()
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

const platformHasBle = false //(Platform.OS === 'ios' || Platform.OS === 'android') && checkIfBLEAvailable()

/**
 * Sidebar component for use in the react-navigation-drawer.
 * @extends PureComponent
 */
export default class Sidebar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  async componentDidMount() {
    this.setState({ email: await AsyncStorage.getItem('@mushrooms_email') })
  }

  /**
   * Helper function to close the drawer. 
   */
  handleMenuPressed = () => {
    this.props.navigation.toggleDrawer()
  }

  /*
   * Handler functions for Sidebar buttons. 
   *
   * The following section is not minimized to a single function 
   * for easier maintainability on the expectation that
   * these functions may become more specialized later on.
   *
   * The modals for each of these buttons are presented on another 
   * screen (ModalScreen), requiring navigation to view. 
   */

  handleViewCachePressed = () => {
    this.props.navigation.navigate('Modal', {
      pressed: 'cache'
    })
  }

  handleViewDataPressed = () => {
    this.props.navigation.navigate('Modal', {
      pressed: 'data'
    })	
  }

  handleSettingsPressed = () => {
    this.props.navigation.navigate('Modal', {
      pressed: 'settings'
    })	
  }

  handleLogoutPressed = () => { 
    this.props.navigation.navigate('Modal', {
      pressed: 'logout'
    })
  }

  handleDevicePressed = () => {
    this.props.navigation.navigate('Modal', {
      pressed: 'device'
    })
  }

  handlePullDeviceMeasuresPressed = () => {
    this.props.navigation.navigate('Modal', {
      pressed: 'pull'
    })
  }


  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <Drawer.Section title="User">
          <Drawer.Item
            label={this.state.email}
          />
        </Drawer.Section>
        <Drawer.Section>
          <Drawer.Item
            label="History"
            icon="history"
            onPress={() => {
              this.handleMenuPressed()
              this.handleViewDataPressed()
            }}
          />
        </Drawer.Section>
        {platformHasBle && // disable devices on the web version
          <Drawer.Section>
            <Drawer.Item 
              label="Devices"
              icon="devices"
              onPress={() => {
                this.handleMenuPressed()
                this.handleDevicePressed()
              }}
            />
            <Drawer.Item 
              label="Pull Device Measures"
              icon="sync"
              onPress={() => {
                this.handleMenuPressed()
                this.handlePullDeviceMeasuresPressed()
              }}
            />
          </Drawer.Section>
        }
        <Drawer.Item
          label="Settings"
          icon="cog"
          onPress={() => {
            this.handleMenuPressed()
            this.handleSettingsPressed()
          }}
        />
        <Drawer.Item
          label="Logout"
          icon="logout"
          onPress={() => {
            this.handleMenuPressed()
            this.handleLogoutPressed()
          }}
        />
        <View style={{ bottom: 6, position: 'absolute', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontFamily: 'Roboto Regular', fontSize: 11 }}>
            {'v' + expo.version}
          </Text>
        </View>
      </SafeAreaView>
    )
  }
}
