import React, { PureComponent } from 'react'
import { TextInput, View, StyleSheet, Keyboard, TouchableWithoutFeedback, Dimensions } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import 'react-native-match-media-polyfill'
import { useMediaQuery } from 'react-responsive'

export default function SearchField(props) {
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 767.98 })
  const { width } = Dimensions.get('window')
  let searchInput
  return (
    <TouchableWithoutFeedback onPress={() => searchInput.focus()}>
      <View style={[styles.searchArea, !isMobileDevice && { width: (width - 48) / 2 - 12 }]}>
        <Ionicons name="md-search" size={16} color="#6b6b6b" />
        <TextInput
          ref={input => searchInput = input}
          style={styles.searchInput}
          placeholder={props.placeholder || 'Search...'}
          placeholderTextColor="#6b6b6b"
          onBlur={Keyboard.dismiss}
          clearButtonMode="always"
          value={props.value}
          onChangeText={props.handleTextChange}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  searchArea: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 4,
    paddingLeft: 12,
    marginBottom: 12,
    marginHorizontal: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.15)'
  },
  searchInput: {
    color: '#6b6b6b',
    fontFamily: 'Roboto Regular',
    marginLeft: 4,
    padding: 8,
    flexGrow: 1
  }
})
