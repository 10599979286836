import React from 'react'
import { KeyboardAvoidingView, View, StyleSheet, Text, TouchableOpacity, Animated, Dimensions, Easing, TouchableWithoutFeedback, ScrollView, Platform } from 'react-native'
import Constants from 'expo-constants'

export default class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fadeAnim: new Animated.Value(0)
    }
  }
  componentDidMount() {
    this.animate()
  }
  animate = () => {
    Animated.timing(
      this.state.fadeAnim,
      {
        toValue: 1,
        duration: 200,
        easing: Easing.bezier(0.39, 0.575, 0.565, 1)
      }
    ).start()
  }
  render() {
    return (
      <Animated.View style={[styles.scrimBackground, { opacity: this.state.fadeAnim }]}>
        <ScrollView scrollEnabled={false} keyboardDismissMode="interactive" contentContainerStyle={{ justifyContent: 'center', alignItems: 'center', height: Dimensions.get('window').height, width: Dimensions.get('window').width }} keyboardShouldPersistTaps={'handled'}>
          <TouchableWithoutFeedback onPress={this.props.handleBackgroundPress}>
            <View style={{ position: 'absolute', top: 0, left: 0, height: Dimensions.get('window').height, width: Dimensions.get('window').width }}>
            </View>
          </TouchableWithoutFeedback>
          <KeyboardAvoidingView behavior="position" enabled>
            <Animated.View
              style={{
                opacity: this.state.fadeAnim,
                transform: [{
                  translateY: this.state.fadeAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-50, 0]
                  })
                }]
              }}>
              <View style={[styles.modal, (Platform.OS !== 'ios' && Platform.OS !== 'android') && { width: 442 }]}>
                <Text style={styles.modalText}>{this.props.modalText}</Text>
                {
                  this.props.errorText && 
                  <Text style={{fontFamily: 'Roboto Regular', fontSize: 16, textAlign: 'center', color: 'red'}}>{this.props.errorText}</Text>
                }
                {this.props.children}
                <View style={styles.modalButtons}>
                  {
                    !!this.props.actionButton1 &&
                      <TouchableOpacity onPress={this.props.handleActionButton1Press} style={[styles.button, { backgroundColor: 'rgba(0,0,0,0.1)', marginRight: 24 }]}>
                        <Text style={styles.actionButtonText1}>{this.props.actionButton1}</Text>
                      </TouchableOpacity>
                  }
                  {
                    !!this.props.actionButton2 &&
                      <TouchableOpacity onPress={this.props.handleActionButton2Press} style={styles.button}>
                        <Text style={styles.actionButtonText2}>{this.props.actionButton2}</Text>
                      </TouchableOpacity>
                  }
                </View>
              </View>
            </Animated.View>
          </KeyboardAvoidingView>
        </ScrollView>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    shadowColor: '#000000',
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginHorizontal: 24,
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 24
  },
  modalText: {
    fontFamily: 'Roboto Regular',
    fontSize: 16,
    paddingTop: 24,
    paddingBottom: 24,
    textAlign: 'center'
  },
  scrimBackground: {
    position: 'absolute',
    width: '100%',
    height: Dimensions.get('window').height,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  actionButtonText1: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#43425D',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  actionButtonText2: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: 'white'
  }
})
