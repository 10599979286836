import React from 'react'
import { ActivityIndicator, StatusBar, StyleSheet, View, Platform } from 'react-native'
import * as Font from 'expo-font'
import Fonts from './assets/fonts'
import AsyncStorage from './AsyncStorage'
import { mapStateToProps, mapDispatchToProps } from './redux/mapping'
import { connect } from 'react-redux'
import AuthContext from './AuthContext'

class LoadingScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false
    }
  }
  async componentDidMount() {
    let items = await AsyncStorage.multiGet(['@mushrooms_accessToken', '@mushrooms_refreshToken', '@mushrooms_email', '@mushrooms_dbUrl', '@mushrooms_indexedFarms'])
    this.props.setAccessToken(items[0][1])
    this.props.setRefreshToken(items[1][1])
    this.props.setEmail(items[2][1])
    if (items[3][1]) {
      this.props.setDbUrl(items[3][1])
    } else {
      this.props.setDbUrl(this.props.dbUrl)
      await AsyncStorage.setItem('@mushrooms_dbUrl', this.props.dbUrl)
    }
    this.props.setIndexedFarms(JSON.parse(items[4][1]) || {})
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.props.watchConnectivityChange()
    } else {
      this.props.setIsConnected(true)
    }
    this.loadFonts('Roboto Bold', 'Roboto Italic', 'Roboto Regular', 'Roboto Black', 'Roboto Medium', 'Roboto Light', 'Roboto Thin')
  }
  async componentDidUpdate(prevProps, prevState) {
    // Navigates user to App if connection is not established when application launches
    if (this.state.isLoaded) {
      if (this.props.isConnected) {
        this.checkUser()
      } else {
        let farms = await AsyncStorage.getItem('@mushrooms_farms').then(val => JSON.parse(val))
        if (Array.isArray(farms)) {
          this.context.setIsSignedIn(true)
        } else {
          this.context.setIsSignedIn(false)
        }
        this.context.setIsLoaded(true)
      }
    }
  }
  async loadFonts() {
    let fonts = {}
    Array.from(arguments).forEach((fontName) => {
      fonts[fontName] = Fonts[fontName]
    })
    await Font.loadAsync(fonts)
    this.setState({ isLoaded: true })
  }

  // Fetch the token from storage then navigate to our appropriate place
  checkUser = async () => {
    let items = await AsyncStorage.multiGet(['@mushrooms_accessToken', '@mushrooms_refreshToken'])
    let accessToken = items[0][1], refreshToken = items[1][1]
    if (accessToken === null && refreshToken === null) {
      this.context.setIsSignedIn(false)
      this.context.setIsLoaded(true)
      return
    }
    let options = {
      method: 'POST',
      headers: {
        'X-Access-Token': accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ records: [] })
    }
    let response = await fetch(`${this.props.dbUrl}/api/records`, options).then(res => res.json())
    if (response.auth === false) {
      try {
        await this.props.updateTokens()
        this.context.setIsSignedIn(true)
      } catch (err) {
        if (err.message === 'Authentication failed.') {
          this.context.setIsSignedIn(false)
        }
      }
    }
    if (response.status === 'success') {
      this.props.setAccessToken(accessToken)
      this.props.setRefreshToken(refreshToken)
      this.context.setIsSignedIn(true)
    }
    this.context.setIsLoaded(true)
  }

  // Render any loading content that you like here
  render() {
    return (
      <View style={styles.loadingScreenContainer}>
        <StatusBar barStyle="default" />
        <ActivityIndicator size="large" color="#43425D" />
      </View>
    )
  }
}

LoadingScreen.contextType = AuthContext

const styles = StyleSheet.create({
  loadingScreenContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textInput: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular',
    width: '100%',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginTop: 12,
    marginBottom: 24
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreen)
