import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { Paragraph, Subheading } from 'react-native-paper'
import { Dropdown } from '../components'
import { useSelector } from 'react-redux'

const DeviceLocation = (props) => {
  let indexedFarms = useSelector(state => state.indexedFarms)
  let [farmId, setFarmId] = useState('')
  let [buildingId, setBuildingId] = useState('')
  let [roomId, setRoomId] = useState('')
  let [farms, setFarms] = useState([])
  let [buildings, setBuildings] = useState([])
  let [rooms, setRooms] = useState([])
  let { options, location, setLocation } = props
  useEffect(() => {
    let { farms, buildings, rooms } = options
    setFarms(farms)
    setBuildings(buildings)
    setRooms(rooms)
  }, [options])
  useEffect(() => {
    let { farmId, buildingId, roomId } = location
    setFarmId(farmId)
    setBuildingId(buildingId)
    setRoomId(roomId)
  }, [location])
  const setFiltered = () => {
    let buildings = [], rooms = []
    if (farmId) {
      buildings = Object.values(indexedFarms[farmId]['buildings'])
        .map(building => {
          if (!buildingId) {
            rooms.push(...building.rooms.map(room => {
              return {label: room.name, value: room.id}
            }))
          }
          return {label: building.name, value: building.id}
        })
    } else {
      ({ buildings } = options)
    }
    if (farmId && buildingId) {
      rooms = indexedFarms[farmId]['buildings'][buildingId]['rooms']
        .map(room => {
          return {label: room.name, value: room.id}
        })
    } else {
      ({ rooms } = options)
    }
    setBuildings(buildings)
    setRooms(rooms)
  }
  useEffect(() => {
    setFiltered()
  }, [farmId, buildingId])
  return (
    <View style={{ paddingHorizontal: 24, paddingTop: 12 }}>
      <Paragraph style={{ marginBottom: 12 }}>Set the location of your device.</Paragraph>
      <Dropdown
        value={farmId}
        onChange={(value) => {
          setFarmId(value)
          setBuildingId('')
          setRoomId('')
        }}
        items={farms}
        placeholder={'Farms...'}
        style={{ marginVertical: 0, marginBottom: 6 }}
      />
      <Dropdown
        value={buildingId}
        onChange={(value) => {
          setBuildingId(value)
          setRoomId('')
        }}
        items={buildings}
        placeholder={'Buildings...'}
        style={{ marginVertical: 0, marginBottom: 6 }}
      />
      <Dropdown
        value={roomId}
        onChange={(value) => {
          setRoomId(value)
          setLocation({ roomId: value })
        }}
        items={rooms}
        placeholder={'Rooms...'}
        style={{ marginVertical: 0, marginBottom: 6 }}
      />
    </View>
  )
}

export default DeviceLocation