import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Platform, Dimensions } from 'react-native'
import AlertDropdown from './AlertDropdown'
import 'react-native-match-media-polyfill'
import { useMediaQuery } from 'react-responsive'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import { useNavigation } from '@react-navigation/native'
import _ from 'underscore'

function RoomCard(props) {
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 767.98 })
  const { width } = Dimensions.get('window')
  const navigation = useNavigation()
  return (
    <View style={[styles.card, !isMobileDevice && { width: (width - 48) / 2 - 12 }]}>
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.cardTitle}>{props.room.name}</Text>
        <Text style={[styles.chipTitle, { fontSize: 12, marginTop: -13 }]}>{`Building: ${props.room.buildingName}\nFarm: ${props.room.farmName}`}</Text>
      </View>
      <AlertDropdown alerts={props.room.alerts || []} room={_.pick(props.room, 'id', 'farmId', 'buildingId')} />
      <Text style={styles.chipTitle}>Add Measures</Text>
      <View style={styles.chipContainer}>
        <TouchableOpacity style={styles.chip} onPress={() => {
          props.navigate(props.room.id, props.room.buildingId, props.room.farmId, 'measure')
          }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.chipText}>By Section</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.chip} onPress={() => navigation.setParams({ roomId: props.room.id, add: 'measure' })}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.chipText}>By Room</Text>
          </View>
        </TouchableOpacity>
      </View>
      <Text style={styles.chipTitle}>Add Crop Inputs</Text>
      <View style={styles.chipContainer}>
        <TouchableOpacity style={styles.chip} onPress={() => {
          props.navigate(props.room.id, props.room.buildingId, props.room.farmId, 'crop input')
        }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.chipText}>By Section</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity style={styles.chip} onPress={() => navigation.setParams({ roomId: props.room.id, add: 'crop input' })}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.chipText}>By Room</Text>
          </View>
        </TouchableOpacity>
      </View>
      <Text style={styles.chipTitle}>Add Crop Outputs</Text>
      <View style={styles.chipContainer}>
        <TouchableOpacity style={styles.chip} onPress={() => navigation.setParams({ roomId: props.room.id, add: 'crop output' })}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.chipText}>By Room</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 12,
    shadowColor: 'black',
    shadowOpacity: 0.25,
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 2,
    elevation: 2,
    marginBottom: 24
  },
  chip: {
    height: 30,
    minWidth: 96,
    backgroundColor: '#d5d5d5',
    paddingHorizontal: 8,
    borderRadius: 15,
    marginRight: 12,
    marginTop: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  chipText: {
    fontSize: 14,
    fontFamily: 'Roboto Regular',
    marginTop: Platform.OS !== 'ios' ? -1 : 0
  },
  chipContainer: {
    flexDirection: 'row'
  },
  chipTitle: {
    fontFamily: 'Roboto Regular',
    fontSize: 14,
    color: '#6b6b6b',
    marginTop: 12,
    paddingVertical: 4
  },
  cardTitle: {
    fontSize: 20,
    fontFamily: 'Roboto Medium',
    marginVertical: 8
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RoomCard)
