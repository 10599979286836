const images = {
  'logos': {
    'simple-logo': require('./logos/simple-logo.webp'),
    'simple-logo-white': require('./logos/simple-logo-white.webp')
  },
  'backgrounds': {
    'shrooms-close': require('./backgrounds/shrooms-close.webp')
  },
  'icons': {
  }
}

export default images
