import React, { PureComponent } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { TagEntryFields } from './'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { TouchableRipple } from 'react-native-paper'

export default class TagEntry extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deletePressed: false
    }
  }
  handleTagEntry = (value, index, type) => {
    this.props.handleTags(
      [
        ...this.props.tags.slice(0, index),
        {
          ...this.props.tags[index],
          [type]: value
        },
        ...this.props.tags.slice(index + 1)
      ]
    )
  }
  handleDelete = (index) => {
    this.props.handleTags(
      [
        ...this.props.tags.slice(0, index),
        ...this.props.tags.slice(index + 1)
      ]
    )
  }
  handleDeleteAll = () => {
    // apparently setState is asynchronous so this needs to be run in a separate callback
    this.setState({ deletePressed: !this.state.deletePressed }, () => {
      if(this.state.deletePressed){
        return
      } 
      this.props.handleTags([])
    })
    
  }
  render() {
    return (
      <View>
        {
          this.props.tags.map((obj, index) => (
            <TagEntryFields
              index={index} 
              handleTagEntry={this.handleTagEntry}
              tagEntryName={this.props.tags[index].name}
              tagEntryValue={this.props.tags[index].value}
              handleDelete={() => this.handleDelete(index)}
            />
          ))
        }
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <TouchableOpacity style={[styles.button, { backgroundColor: 'rgba(0,0,0,0.1)', flex: 1 }]} onPress={() => this.props.handleTags([...this.props.tags, { name: '', value: '' }])}>
            <MaterialCommunityIcons style={{ marginLeft: -4 }} name={'plus-circle-outline'} size={20} color={'#43425D'} />
            <Text style={[styles.buttonText, { marginLeft: 6 }]}>Add Tag</Text>
          </TouchableOpacity>
          {
            this.props.tags.length > 0 &&
              <TouchableRipple 
                style={[styles.iconButton, { 
                  backgroundColor: this.state.deletePressed ? '#f44335' : '#eaeaea', 
                  marginVertical: 12, 
                  marginLeft: 12 }
                ]} 
                  onPress={this.handleDeleteAll}
              >
                <MaterialCommunityIcons 
                  name={'delete-sweep'} 
                  size={20}
                  color={this.state.deletePressed ? '#f8f9fa' : '#43425D'}
                />
              </TouchableRipple>
          }
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginVertical: 12,
    minWidth: 120,
    borderRadius: 4
  },
  buttonText: {
    fontFamily: 'Roboto Medium',
    fontSize: 16,
    color: '#43425D'
  },
  iconButton: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 4,
    height: 43,
    width: 43,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
