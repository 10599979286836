import {
  StateProps,
  DispatchStateActions,
  setIsConnected, 
  setAccessToken, 
  setRefreshToken, 
  setEmail, 
  setSaves, 
  setDbUrl, 
  setIndexedFarms,
  setDevice,
  setThermo,
  setDeviceList,
  watchConnectivityChange, 
  updateTokens, 
  deleteSaveData, 
  farmsUpdated, 
  updateFarms, 
  pushOfflineCache, 
  checkBlePermissions,
  scanAndListDevices,
  connectToDevice,
  scanAndConnect, 
  readAndCollect,
  writeToDevice, 
  disconnectDevice,
  grabFromThermo
} from '.'

import { Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { Device } from 'react-native-ble-plx'
import { Farm } from '../type/api/farms'
import { IndexedFarms } from '.'

const mapStateToProps = (state: StateProps): StateProps => {
  return {
    isConnected: state.isConnected,
    accessToken: state.accessToken,
    refreshToken: state.refreshToken,
    email: state.email,
    saves: state.saves,
    dbUrl: state.dbUrl,
    indexedFarms: state.indexedFarms,
    bleManager: state.bleManager,
    device: state.device,
    thermo: state.thermo,
    deviceList: state.deviceList
  }
}
//@ts-ignore Type 'DispatchStateActions' does not satisfy the constraint 'Action<any>'.
const mapDispatchToProps = (dispatch: ThunkDispatch<StateProps, null, DispatchStateActions>) => {
  return {
    setIsConnected: (isConnected: boolean) => {
      dispatch(setIsConnected(isConnected))
    },
    setAccessToken: (accessToken: string) => {
      dispatch(setAccessToken(accessToken))
    },
    setRefreshToken: (refreshToken: string) => {
      dispatch(setRefreshToken(refreshToken))
    },
    setEmail: (email: string) => {
      dispatch(setEmail(email))
    },
    setSaves: (saves: number) => {
      dispatch(setSaves(saves))
    },
    setDbUrl: (dbUrl: string) => {
      dispatch(setDbUrl(dbUrl))
    },
    setDevice: (device: Device) => {
      dispatch(setDevice(device))
    },
    setThermo: (thermo: Device) => {
      dispatch(setThermo(thermo))
    },
    setDeviceList: (deviceList: Device[]) => {
      dispatch(setDeviceList(deviceList))
    },
    setIndexedFarms: (indexedFarms: {}) => {
      dispatch(setIndexedFarms(indexedFarms))
    },
    watchConnectivityChange: () => {
      dispatch(watchConnectivityChange())
    },
    updateTokens: () => {
      return new Promise(async (resolve, reject) => {
        try {
          let tokens = await dispatch(updateTokens())
          resolve(tokens)
        } catch (err) {
          reject(err)
        }
      })
    },
    deleteSaveData: (id: any, deviceSaves: any) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          await dispatch(deleteSaveData(id, deviceSaves))
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    farmsUpdated: () => {
      return new Promise(async (resolve, reject) => {
        try {
          let updated = await dispatch(farmsUpdated())
          resolve(updated)
        } catch (err) {
          reject(err)
        }
      })
    },
    updateFarms: () => {
      return new Promise<{farms: Farm[], indexedFarms: IndexedFarms}>(async (resolve, reject) => {
        try {
          let { farms, indexedFarms } = await dispatch(updateFarms())
          resolve({ farms, indexedFarms })
        } catch (err) {
          reject(err)
        }
      })
    },
    pushOfflineCache: () => {
      dispatch(pushOfflineCache())
    },
    checkBlePermissions: () => {
      return new Promise(async (resolve, reject) => {
        try {
          let granted = await dispatch(checkBlePermissions())
          resolve(granted)
        } catch (err) {
          reject(err)
        }
      })
    },
    scanAndListDevices: () => {
      return new Promise<Device[]>(async (resolve, reject) => {
        try {
          let deviceList = await dispatch(scanAndListDevices())
          resolve(deviceList)
        } catch (err) {
          reject(err)
        }
      })
    },
    connectToDevice: (device: Device) => {
      return new Promise<Device>(async (resolve, reject) => {
        try {
          let _device = await dispatch(connectToDevice(device))
          resolve(_device)
        } catch (err) {
          reject(err)
        }
      })
    },
    scanAndConnect: () => {
      return new Promise(async (resolve, reject) => {
        try {
          let device = await dispatch(scanAndConnect())
          resolve(device)
        } catch (err) {
          reject(err)
        }
      })
    },
    readAndCollect: () => {
      return new Promise<string>(async (resolve, reject) => {
        try {
          let data = await dispatch(readAndCollect())
          resolve(data)
        } catch (err) {
          reject(err)
        }
      })
    },
    writeToDevice: (location: any) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          await dispatch(writeToDevice(location))
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    disconnectDevice: (id?: any) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          await dispatch(disconnectDevice(id))
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    grabFromThermo: (device?: Device) => {
      return new Promise<
        Awaited<ReturnType<ReturnType<typeof grabFromThermo>>>
      >(async(resolve, reject) => {
        try {
          if(device) {
            resolve(await dispatch(grabFromThermo(device)))
          } else {
            resolve(await dispatch(grabFromThermo()))
          }
        } catch (err) {
          reject(err)
        }
      })
    }
  }
}

export {
  mapStateToProps,
  mapDispatchToProps
}
