import React, { useState, useEffect } from 'react'
import { View, ScrollView, ActivityIndicator } from 'react-native'
import { List, Caption, Button } from 'react-native-paper'
import { DeviceDataView } from '.'
import { connect, ConnectedProps } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import { Buffer } from 'buffer'
import { Device } from 'react-native-ble-plx'

const connector = connect(mapStateToProps, mapDispatchToProps)
interface DeviceListViewOwnProps {
  deviceType: string
  addModalThermoSelecting: boolean
}
type DeviceListViewProps = ConnectedProps<typeof connector> & DeviceListViewOwnProps

/**
 * Scans and lists all ble devices available. 
 * The BLE scanner functions are located in redux/index.js.
 * @param {DeviceListViewProps} props 
 * @returns 
 */
const DeviceListView = (props: DeviceListViewProps) => {
  const [deviceList, setDeviceList] = useState([] as Device[])
  const [isLoaded, setIsLoaded] = useState(false)
  const [scanning, setScanning] = useState(true)
  const [selectedDevice, setSelectedDevice] = useState(null as Device)
  const [location, setLocation] = useState({})

  const gatherDevices = () => {
    return new Promise<Device[]>(async (resolve, reject) => {
      try {
        setIsLoaded(true)
        let devices = await props.scanAndListDevices()
        setDeviceList(devices)
        if(devices.length === 0) {
          setIsLoaded(false) //keep scanning if no devices found
        }
        resolve(devices)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }
  const loadAsync = async () => {
    try {
      await gatherDevices()
      setIsLoaded(true)
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    if (!isLoaded) {
      loadAsync()
    }
  }, [isLoaded])

  /** @param {number} txPowerLevel */
  const determineNetworkIcon = (txPowerLevel: number) => {
    console.log(txPowerLevel)
    if (txPowerLevel === null) return 'bluetooth-connect'
    if (txPowerLevel >= -50) return 'network-strength-4'
    else if (txPowerLevel >= -60) return 'network-strength-3'
    else if (txPowerLevel >= -70) return 'network-strength-2'
    else if (txPowerLevel >= -80) return 'network-strength-1'
    else return 'network-strength-outline'
  }
  if (deviceList.length === 0) {
    return (
      <View style={{ alignItems: 'center', padding: 24 }}>
        <ActivityIndicator size="small" color="#888888" />
        <Caption>Scanning</Caption>
      </View>
    )
  }

  const handleConnectDeviceButton = (device) => {
    setSelectedDevice(device)
  }

  if(selectedDevice) {
    let type = 'unknown'
    if (
      Buffer
        .from(String(selectedDevice.manufacturerData), 'base64')
        .toString('utf-8')
        .includes('INTELLI_ROCKS')
    ) {
      type = 'thermo'
      props.setThermo(selectedDevice)
    } else if (
      selectedDevice.name === 'AgriPy' || selectedDevice.name === 'raspberrypi'
    ) {
      type = 'agri'
    }
    props.setDevice(selectedDevice)
    //@ts-ignore
    return <DeviceDataView device={selectedDevice} deviceType={type} addModalThermoSelecting={props.addModalThermoSelecting}/>
  }

  return (
    <ScrollView>
      {deviceList.map((device, i) => (
        <List.Item
          title={device.name ?? (
            Buffer
              .from(String(device.manufacturerData), 'base64')
              .toString('utf-8')
              .includes('INTELLI_ROCKS') ? 'Thermometer' : 'Unknown'
            )}
          description={device.id}
          left={() => <List.Icon icon={determineNetworkIcon(device.txPowerLevel ?? device.rssi)} />}
          right={() => <Button style={{marginTop: 10, maxHeight: 38}} mode="contained" onPress={() => handleConnectDeviceButton(device)}>Connect</Button>}
          key={i}
        />
      )
      )}
    </ScrollView>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceListView)