import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { Menu, TouchableRipple, IconButton } from 'react-native-paper'
import _ from 'underscore'

const Dropdown = (props) => {
  const [visible, setVisible] = useState(false)
  const [dropdownWidth, setDropdownWidth] = useState(0)
  const { placeholder, pic, items, value } = props
  const placeholderItem = placeholder ?
    { label: placeholder, value: null } :
    { label: 'Select an item...', value: null }
  const indexedItems = _.indexBy([placeholderItem, ...items], 'value')
  const onItemPress = (item) => {
    setVisible(false)
    props.onChange(item.value)
  }
  return (
    <View style={[{ flexDirection: 'row', width: '100%' }, props.style]}>
      <View style={{ flex: 1 }}>
        <Menu
          visible={visible}
          onDismiss={() => setVisible(false)}
          contentStyle={{ width: dropdownWidth }}
          anchor={
            <View onLayout={(e) => setDropdownWidth(e.nativeEvent.layout.width)}>
              <TouchableRipple disabled={items.length === 0} onPress={(e) => setVisible(true)} rippleColor={'rgba(0, 0, 0, 0.1)'} style={[styles.dropdown, props.style]}>
                <View>
                  <Text style={[styles.dropdownText, { paddingLeft: pic ? 24 : 12 } ]} numberOfLines={1}>{indexedItems[value] ? indexedItems[value].label : indexedItems[null].label}</Text>
                  <View style={styles.preDropdownIcon}>
                    <IconButton disabled={items.length === 0} icon={pic} size={20} color={'rgb(107, 107, 107)'} onPress={() => setVisible(true)} />
                  </View>
                  <View style={styles.dropdownIcon}>
                    <IconButton disabled={items.length === 0} icon="chevron-down" size={20} color={'rgb(107, 107, 107)'} onPress={() => setVisible(true)} />
                  </View>
                </View>
              </TouchableRipple>
            </View>
          }
        >
          {
            [placeholderItem, ...items].map((item, index) => (
              <Menu.Item key={`menu-item-${index}`} style={{ maxWidth: '100%' }} titleStyle={item.value === null && { color: '#888888' }} onPress={() => onItemPress(item)} title={item.label} />
            ))
          }
        </Menu>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    paddingVertical: 12,
    paddingLeft: 4,
    marginVertical: 12,
    position: 'relative'
  },
  dropdownText: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontFamily: 'Roboto Regular'
  },
  dropdownIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    justifyContent: 'center'
  },
  preDropdownIcon: {
    position: 'absolute',
    top: 0,
    left: -10,
    height: '100%',
    justifyContent: 'center'
  }
})

export default Dropdown