import React from 'react'
import { View, StatusBar } from 'react-native'
import { AppBar, OfflineNotice, Breadcrumbs } from './'
import Constants from 'expo-constants'

const Header = () => {
    return (
        <View>
            <StatusBar barStyle="light-content" />
            <View style={{ height: Constants.statusBarHeight, backgroundColor: '#43425D' }}>
            </View>
            <AppBar />
            <OfflineNotice />
            <Breadcrumbs items={['Farms', 'Buildings', 'Rooms', 'Sections', 'SectionsAlt']} />
        </View>
    )
}

export default Header