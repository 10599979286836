import React from 'react'
import { View, TouchableHighlight, Animated, TouchableWithoutFeedback, StyleSheet } from 'react-native'
import { Text, Card, Subheading, Divider } from 'react-native-paper'
import moment from 'moment'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import { Square } from '../type/api/farms'
import { IndexedFarms } from '../redux'
import { Record } from '../type/api/records'

type TypedRecord = { type: string, typeName: string } & Record

type IndexedRooms = { 
  [key: string]: { name: string, farmId: number, buildingId: string} | {}
}

type IndexedSections = {
  [key: string]: Square | {}
}

interface DataViewCardProps {
  rooms?: IndexedRooms
  sections?: IndexedSections
  value: TypedRecord
  handleDeleteData?: (value: any) => Promise<void>
}


const DataViewCard = (props: DataViewCardProps) => {
  const { rooms, sections, value } = props
  const [dropAnim] = React.useState(new Animated.Value(0))
  //@ts-expect-error
  const indexedFarms: IndexedFarms = useSelector(state => state.indexedFarms)
  const process = (data: TypedRecord) => {
    let valueTypes = ['intCount', 'booleanValue', 'doubleMeasure']
    for (let i = 0; i < valueTypes.length; i++) {
      let value: boolean | number = data[valueTypes[i]]
      if (typeof value === 'boolean') {
        return value ? 'Presence' : 'Absence'
      }
      if (!isNaN(Number(String(value)))) {
        return Number(value).toFixed(7).toString()
      }
    }
    return 'N/A'
  }
  const identify = (value: TypedRecord) => {
    switch (value.type) {
      case 'records':
        return 'Measure'
      case 'cropInputs':
        return 'Crop Input'
      case 'cropOutputs':
        return 'Crop Output'
      default:
        return ''
    }
  }
  return (
    <Card style={{ elevation: 2, marginHorizontal: 24, marginVertical: 6 }}>
      <Card.Content style={{ paddingHorizontal: 0, paddingVertical: 0 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 4, paddingHorizontal: 12 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Subheading style={{ fontFamily: 'Roboto Medium', letterSpacing: 2, color: '#888888', fontSize: 14 }}>
              { (identify(value)).toUpperCase() }
            </Subheading>
            {
              value.id.split('-').length === 5 &&
                <MaterialCommunityIcons name="content-save-alert-outline" size={16} style={{ marginLeft: 4 }} />
            }
          </View>
          <Subheading>
            { moment(value.recordedAt).format('LT') }
            { // Display date if more than one day has passed
              moment().diff(moment(value.recordedAt), 'days') > 0 && 
              <Subheading>{ '  ' + moment(value.recordedAt).format('M/D/YY') }</Subheading>
            }
          </Subheading>
        </View>
        <Divider />
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical: 8, paddingHorizontal: 12 }}>
          <View style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginRight: 12 }}>
            <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>TYPE</Text>
            <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>{value.typeName}</Text>
          </View>
          <View style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
            <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>VALUE</Text>
            <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>{process(value)}</Text>
          </View>
          {props.handleDeleteData &&
            <View style={{ flex: 0.5, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <TouchableHighlight style={{ width: 36, height: 36, borderRadius: 18, backgroundColor: '#e7e7e7', justifyContent: 'center', alignItems: 'center' }} underlayColor="#888888" onPress={() => props.handleDeleteData(value)}><MaterialCommunityIcons name="delete" size={18} /></TouchableHighlight>
            </View>
          }
        </View>
        { (rooms || sections) &&
        <View>
          <Divider />
          <Animated.View style={{ position: 'relative', overflow: 'hidden',  marginVertical: 8,
            height: dropAnim.interpolate({ inputRange: [0, 1], outputRange: [12, 89] })
          }}>
            <View style={{ marginBottom: 8, paddingHorizontal: 12, flexDirection: 'row', width: '100%', height: 36 }}>
              <Animated.View style={{ opacity: dropAnim, flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginRight: 12 }}>
                <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>ROOM</Text>
                <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>{rooms[value.roomId]?.name}</Text>
              </Animated.View>
              {
                value.squareId &&
                  <Animated.View style={{ opacity: dropAnim, flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>SECTION</Text>
                    <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>
                      {sections[value.squareId]?.name}
                    </Text>
                  </Animated.View>
              }
              <View style={{ flex: 0.5 }}></View>
            </View>
            <Divider />
            <View style={{ marginTop: 8, paddingHorizontal: 12, flexDirection: 'row', width: '100%', height: 36 }}>
              <Animated.View style={{ opacity: dropAnim, flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginRight: 12 }}>
                <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>FARM</Text>
                <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>{indexedFarms[rooms[value.roomId]?.farmId]?.name}</Text>
              </Animated.View>
              <Animated.View style={{ opacity: dropAnim, flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
                <Text style={{ fontFamily: 'Roboto Medium', color: '#888888', letterSpacing: 1.5, fontSize: 10 }}>BUILDING</Text>
                <Text style={{ fontFamily: 'Roboto Medium', fontSize: 18, marginTop: 2 }}>{indexedFarms[rooms[value.roomId]?.farmId]?.buildings[rooms[value.roomId]?.buildingId].name}</Text>
              </Animated.View>
              <View style={{ flex: 0.5 }}></View>
            </View>
            <TouchableWithoutFeedback onPress={() => {
              if (dropAnim._value === 1) {
                Animated.timing(dropAnim, { toValue: 0, duration: 200, useNativeDriver: false }).start()
              } else if (dropAnim._value === 0) {
                Animated.timing(dropAnim, { toValue: 1, duration: 200, useNativeDriver: false }).start()
              }
            }}>
              <View style={{ position: 'absolute', top: -5, right: -4, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start', paddingHorizontal: 12 }}>
                <Animated.Text style={{ opacity: dropAnim.interpolate({ inputRange: [0, 1], outputRange: [1, 0] }), fontFamily: 'Roboto Medium', letterSpacing: 1.5, fontSize: 10, marginRight: 2, marginTop: 5, color: '#888888' }}>LOCATION</Animated.Text>
                <Animated.View style={{ transform: [{ rotate: dropAnim.interpolate({ inputRange: [0, 1], outputRange: ['0deg', '180deg'] }) }] }}>
                  <MaterialCommunityIcons name="chevron-down" size={20} />
                </Animated.View>
              </View>
            </TouchableWithoutFeedback>
          </Animated.View>
        </View>
      }
      </Card.Content>
    </Card>
  )
}

export default DataViewCard
