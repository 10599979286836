import React, { useState, useEffect, useContext } from 'react'
import { LogBox } from 'react-native'
import { DefaultTheme, configureFonts, Provider as PaperProvider } from 'react-native-paper'
import RoomsScreen from './RoomsScreen'
import AuthScreen from './AuthScreen'
import LoadingScreen from './LoadingScreen'
import ForgotScreen from './ForgotScreen'
import SectionsScreen from './SectionsScreen'
import SectionsScreenAlt from './SectionsScreenAlt'
import BuildingsScreen from './BuildingsScreen'
import FarmsScreen from './FarmsScreen'
import ModalScreen from './ModalScreen'
import { store } from './redux'
import { Provider } from 'react-redux'
import { Sidebar, Header } from './components'
import 'react-native-gesture-handler'
import { LinkingOptions, NavigationContainer, RouteProp } from '@react-navigation/native'
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack'
import { createDrawerNavigator, DrawerNavigationProp, DrawerScreenProps } from '@react-navigation/drawer'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import AuthContext from './AuthContext'
import * as Linking from 'expo-linking'
import * as Permissions from 'expo-permissions'
import * as Notifications from 'expo-notifications'

LogBox.ignoreLogs(['Require cycle', 'expo-permissions is now deprecated'])

const Stack = createStackNavigator()

const Drawer = createDrawerNavigator()

type MainStackParamList = {
  Farms: undefined
  Buildings: { farmId: string }
  Rooms: undefined
  Sections: {roomId: string, buildingId: string, farmId: string, add?: string, recordType?: string }
  SectionsAlt: {sectionId?: string, roomId: string, buildingId: string, farmId: string, add: string }
}

export type FarmScreenProps = { navigation: DrawerNavigationProp<MainStackParamList, 'Farms'> }
export type SectionsScreenProps = DrawerScreenProps<MainStackParamList, 'Sections'>
export type SectionsScreenAltProps = DrawerScreenProps<MainStackParamList, 'SectionsAlt'>

const MainStackScreen = () => {
  Permissions.usePermissions(Permissions.NOTIFICATIONS, { ask: true })
  return (
    <Drawer.Navigator
      initialRouteName="Farms"
      backBehavior="order"
      drawerContent={props => <Sidebar {...props} />}
      screenOptions={{
        header: (props) => <Header {...props} />
      }}
    >
      <Drawer.Screen name="Farms" component={FarmsScreen} />
      <Drawer.Screen name="Buildings" component={BuildingsScreen} />
      <Drawer.Screen name="Rooms" component={RoomsScreen} />
      <Drawer.Screen name="Sections" component={SectionsScreen} />
      <Drawer.Screen name="SectionsAlt" component={SectionsScreenAlt} />
    </Drawer.Navigator>
  )
}
const RootStack = createStackNavigator()

type AuthStackParamList = {
  Auth: undefined
  Forgot: undefined
  Modal: {pressed: 'settings'}
}

export type AuthScreenProps = {
  navigation: StackNavigationProp<AuthStackParamList, 'Auth'>
}

export type ForgotScreenProps = {
  navigation: StackNavigationProp<AuthStackParamList, 'Modal'>
}

const AuthStack = createStackNavigator()

const AuthStackScreen = () => (
  <AuthStack.Navigator screenOptions={{ headerShown: false }}>
    <AuthStack.Screen name="Auth" component={AuthScreen} />
    <AuthStack.Screen name="Forgot" component={ForgotScreen} />
  </AuthStack.Navigator>
)

type RootStackParamList = {
  Modal?: { 
    pressed: 'settings' | 'cache' | 'data' | 'saves' | 'logout' | 'pull' | 'device'
    addModalThermoSelecting?: boolean 
  }
}

export type ModalScreenProps = {
  route: RouteProp<RootStackParamList, 'Modal'>
  navigation: StackNavigationProp<RootStackParamList, 'Modal'>
}

const RootStackScreen = () => {
  let { isSignedIn } = useContext(AuthContext)
  return (
    <RootStack.Navigator screenOptions={{ headerShown: false }}>
      {
        isSignedIn ? (
          <RootStack.Screen name="Main" component={MainStackScreen} />
        ) : (
          <RootStack.Screen name="AuthStack" component={AuthStackScreen} />
        )
      }
      <RootStack.Screen 
        name="Modal" 
        component={ModalScreen} 
        options={{presentation: 'transparentModal'}} 
      />
    </RootStack.Navigator>
  )
}

const prefix = Linking.createURL('/')

//console.log(prefix)

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: ['http://localhost:19006', 'https://app.cropsmarts.com', prefix],
  config: {
    screens: {
      Root: {
        initialRouteName: 'Main',
        screens: {
          Main: {
            screens: {
              Farms: 'farms',
              Buildings: ':farmId?/buildings',
              Rooms: ':farmId?/:buildingId?/rooms',
              Sections: ':farmId/:buildingId/:roomId/sections',
              SectionsAlt: ':farmId/:buildingId/:roomId/sectionsAlt'
            }
          },
          Modal: {
            path: 'modal/:pressed'
          },
          Auth: {
            screens: {
              Auth: 'login',
              Forgot: 'reset-password'
            }
          }
        }
      }
    }
  }
}

const fontConfig = {
  ios: {
    regular: {
      fontFamily: 'Roboto Medium'
    },
    medium: {
      fontFamily: 'Roboto Medium'
    },
    light: {
      fontFamily: 'Roboto Light'
    },
    thin: {
      fontFamily: 'Roboto Thin'
    }
  },
  web: {
    regular: {
      fontFamily: 'Roboto Medium'
    },
    medium: {
      fontFamily: 'Roboto Medium'
    },
    light: {
      fontFamily: 'Roboto Light'
    },
    thin: {
      fontFamily: 'Roboto Thin'
    }
  }
}

const theme = {
  ...DefaultTheme,
  fonts: configureFonts(fontConfig),
  colors: {
    ...DefaultTheme.colors,
    primary: '#43425D',
    accent: '#EAEAEA'
  }
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true
  })
})

export default function App() {
  let [isSignedIn, setIsSignedIn] = useState(false)
  let [isLoaded, setIsLoaded] = useState(false)
  if (!isLoaded) {
    return (
      <Provider store={store}>
        <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, isLoaded, setIsLoaded }}>
          <LoadingScreen />
        </AuthContext.Provider>
      </Provider>
    )
  }
  if (!isSignedIn) {
    linking.config.screens.Root.initialRouteName = 'AuthStack'
  }
  return (
    <Provider store={store}>
      <PaperProvider theme={theme}>
        <SafeAreaProvider>
          <NavigationContainer linking={linking}>
            <AuthContext.Provider value={{ isSignedIn, setIsSignedIn, isLoaded, setIsLoaded }}>
              <Stack.Navigator screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Root" component={RootStackScreen} />
              </Stack.Navigator>
            </AuthContext.Provider>
          </NavigationContainer>
        </SafeAreaProvider>
      </PaperProvider>
    </Provider>
  )
}
