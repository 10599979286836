import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { View, ActivityIndicator, FlatList, Platform, NativeModules } from 'react-native'
import { Caption, Text, Subheading } from 'react-native-paper'
import { mapStateToProps, mapDispatchToProps } from '../redux/mapping'
import { DataViewCard } from './'
import moment from 'moment'
import AsyncStorage from '../AsyncStorage'
import uuid from 'uuid/v4'

/**
 * @typedef DeviceDataPullerProps
 * @type {object}
 * @property {function():Promise<Array.<import('react-native-ble-plx').Device>>} scanAndListDevices
 * @property {function():Promise<import('react-native-ble-plx').Device>} connectToDevice
 * @property {function():Promise<object>} readAndCollect
 */

/**
 * Scans and lists all ble devices available. 
 * The BLE scanner functions are located in redux/index.js.
 * @param {DeviceDataPullerProps} props 
 * @returns 
 */
const DeviceDataPuller = (props) => {
  const [data, setData] = useState([])
  const [deviceList, setDeviceList] = useState([])
  const [numScanned, setNumScanned] = useState(deviceList.length)
  const [isLoaded, setIsLoaded] = useState(false)
  const [scanning, setScanning] = useState(true)
  const [finishedPulling, setFinishedPulling] = useState(false)
  //const [selectedDevice, setSelectedDevice] = useState(null)
  const [rooms, setRooms] = useState({})
  const [sections, setSections] = useState({})
  const [indexedMeasures, setIndexedMeasures] = useState({})
  const [location, setLocation] = useState({})
  const [options, setOptions] = useState({ farms: [], buildings: [], rooms: [] })
  const [numNotConfigured, setNumNotConfigured] = useState(0)
  const [numReceived, setNumReceived] = useState(0)
  const { indexedFarms, accessToken, dbUrl } = props

  const android = Platform.OS === 'android'
  const ios = Platform.OS === 'ios'

  /**
   * @typedef LocationObject
   * @property {number} roomId
   */
  /**
   * @typedef HistoryValues 
   * @property {string} id
   * @property {string} _rev
   * @property {number} temperature
   * @property {number} humidity
   * @property {number} co2
   * @property {number} tvoc
   * @property {LocationObject} location
   */
  /**
   * @typedef MeasuresInServerFormat
   * @property {doubleMeasure} number
   * @property {string} id
   * @property {measureTypeId} number
   * @property {recordedAt} string
   * @property {roomId} number
   * @property {unknown} tags
   */
  /**
   * @typedef GatherMeasuresBClassicAndroidReturn
   * @property {Array.<MeasuresInServerFormat>} records
   * @property {Array.<HistoryValues>} receivedData
   */

  /**
   * @param {import('react-native-ble-plx').Device} device 
   * @returns {Promise.<GatherMeasuresBClassicAndroidReturn>}
   */
  const gatherMeasuresFromBClassicDeviceAndroid = async (device) => {
    const address = device.id
    const bclassic = NativeModules.BluetoothClassicModule
    bclassic.setAddress(address)
    bclassic.connectToDevice()

    const rawValues = await bclassic.readHistoryValues()
    /**@type {Array.<HistoryValues>} */
    const receivedData = JSON.parse(rawValues)

    const newIndexedMeasures = {}
    const records = []
    for (let data of receivedData) {
      let { roomId, squareId } = data.location || {}
      if(!roomId) {
        console.warn('The record has no location')
        continue
      }
      let _location = format(data.location)
      let { farmId } = _location
      if(!farmId) {
        // console.log(_location)
        // console.error('farmId not determined yet')
        console.warn('farmId not determined yet')
        continue
      }

      let { measureTypes } = indexedFarms[farmId]
      for (let measureTypeId in measureTypes) {
        let { measureTypeName } = measureTypes[measureTypeId]
        if (data[measureTypeName.toLowerCase()] !== undefined) {
          let body = { measureTypeId, roomId: String(roomId), id: uuid() }
          body.doubleMeasure = data[measureTypeName.toLowerCase()]
          body.recordedAt = data.id
          body.tags = { deviceId: device.id, deviceIdType: Platform.OS === 'ios' ? 'uuid' : Platform.OS === 'android' ? 'mac' : 'unknown' }
          newIndexedMeasures[measureTypeId] = measureTypes[measureTypeId]
          if (squareId) body.squareId = squareId
          records.push(body)
        }
      }
    }
    setIndexedMeasures({ ...indexedMeasures, ...newIndexedMeasures })

    return { records, receivedData }
  }

  /**
   * @typedef DeleteRowObject
   * @property {boolean} _deleted
   * @property {string} _id
   * @property {string} _rev
   */
  /**
   * @param {Array.<HistoryValues>} data 
   */
   function cleanupData(data) {
    /**@type {Array.<DeleteRowObject>} */
    const result = []
    for(let doc of data) {
      let row = {
        _deleted: true,
        _id: doc.id,
        _rev: doc["_rev"]
      }
      result.push(row)
    }
    return result 
  }

  /**
   * @param {import('react-native-ble-plx').Device} device 
   * @returns 
   */
  const gatherMeasureFromDevice = (device) => {
    return new Promise(async (resolve, reject) => {
      try {
        await props.connectToDevice(device)
        setScanning(false)
        let data = await props.readAndCollect().then(val => JSON.parse(val)).catch(err => { return {} })
        let { roomId, squareId } = data.location || {}
        let _location = format(data.location)
        let { farmId } = _location
        if(!farmId) {
          // console.log(_location)
          // console.error('farmId not determined yet')
          reject('farmId not determined yet')
          return
        }

        let records = []
        let newIndexedMeasures = {}
        let { measureTypes } = indexedFarms[farmId]
        for (let measureTypeId in measureTypes) {
          let { measureTypeName } = measureTypes[measureTypeId]
          if (data[measureTypeName.toLowerCase()] !== undefined) {
            let body = { measureTypeId, roomId: String(roomId), id: uuid() }
            body.doubleMeasure = data[measureTypeName.toLowerCase()]
            body.recordedAt = moment().format()
            body.tags = { deviceId: device.id, deviceIdType: Platform.OS === 'ios' ? 'uuid' : Platform.OS === 'android' ? 'mac' : 'unknown' }
            newIndexedMeasures[measureTypeId] = measureTypes[measureTypeId]
            if (squareId) body.squareId = squareId
            records.push(body)
          }
        }
        setIndexedMeasures({ ...indexedMeasures, ...newIndexedMeasures })
        resolve(records)
        format(data.location)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  /**
   * @typedef AgriRecord
   * @property {number} doubleMeasure
   * @property {string} id a uuid
   * @property {string} measureTypeId
   * @property {string} recordedAt a Date
   * @property {number} roomId 
   */

  /**
   * Send a record to mushserver. 
   * 
   * If the device is not connected to the internet,
   * save data to cache, and if there is an error at sending,
   * save to cache. Otherwise record the data directly. 
   * @param {Array.<AgriRecord>} records 
   */
  const send = async (records) => {
    const cacheData = async () => {
      let offlineCache = await AsyncStorage
        .getItem('@mushrooms_offlineCache')
        .then(val => JSON.parse(val))
      let property = 'records'
      offlineCache = {
        ...offlineCache,
        [property]: [
          ...(offlineCache[property] ? offlineCache[property] : []),
          ...records
        ]
      }
      console.log(offlineCache)
      await AsyncStorage.setItem(
        '@mushrooms_offlineCache',
        JSON.stringify(offlineCache)
      )
      return records
    }
    if (props.isConnected) {
      //helper function for caching data
      let options = {
        headers: {
          'X-Access-Token': props.accessToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ records })
      }
      const res = await fetch(`${props.dbUrl}/api/records`, options)
      if (!res.ok) {
        //TODO: inform user that the data failed to send
        console.warn('Failed to send. Caching data.', await res.text())
        return await cacheData()
      }
      const data = await res.text()
      try {
        const info = JSON.parse(data)
        if (info.auth === false) {
          await props.updateTokens()
          return await send(records)
        }
        if (info.status === 'success') {
          if(ios) {
            let deviceSaves = await AsyncStorage
              .getItem('@mushrooms_deviceSaves')
              .then(val => JSON.parse(val))
            let property = 'records'
            deviceSaves = {
              ...deviceSaves,
              [property]: [
                ...(deviceSaves[property] ? deviceSaves[property] : []), 
                info.data
              ]
            }
            await AsyncStorage.setItem(
              '@mushrooms_deviceSaves',
              JSON.stringify(deviceSaves)
            )
            props.setSaves(props.saves + 1)
          }
          return info.data
        }
      } catch (err) {
        console.error(err)
        if (err.message === 'Authentication failed.') {
          // authContext.setIsSignedIn(false)
          // authContext.setIsLoaded(false)
        }
        return await cacheData()
      }
    } else {
      return await cacheData()
    }
  }

  const format = (location = {}) => {
    let roomsChanged = false, sectionsChanged = false
    let { roomId, squareId } = location
    if (!rooms[roomId]) {
      roomsChanged = true
    }
    if (squareId) {
      if (!sections[squareId]) {
        sectionsChanged = true
      }
    }
    if (roomsChanged || sectionsChanged) {
      let newRooms = {}, newSections = {}
      newRooms[roomId] = {}
      if (squareId) newSections[squareId] = {}
      let options = { farms: [], buildings: [], rooms: [] }
      let farmId, buildingId
      Object.values(indexedFarms).forEach(farm => {
        options.farms.push({ label: farm.name, value: farm.id })
        Object.values(farm.buildings).forEach(building => {
          options.buildings.push(
            { label: building.name, value: building.id }
          )
          building.rooms.forEach(room => {
            options.rooms.push({ label: room.name, value: room.id })
            if (newRooms[room.id]) {
              newRooms[room.id] = {
                name: room.name,
                farmId: farm.id,
                buildingId: building.id
              }
            }
            if (room.id == roomId) {
              farmId = farm.id
              buildingId = building.id
            }
            if (squareId) {
              room.rows.forEach(row => {
                row.levels.forEach(level => {
                  level.squares.forEach(square => {
                    if (newSections[square.id]) newSections[square.id] = square
                  })
                })
              })
            }
          })
        })
      })
      setOptions(options)
      setRooms({ ...rooms, ...newRooms })
      setSections({ ...sections, ...newSections })
      const newLocation = { farmId, buildingId, roomId, squareId }
      setLocation(newLocation)
      return newLocation
    }
  }

  const gatherAllMeasures = async () => {
    let newData = []
    let _numNotConfigured = 0
    let numReads = 0
    for (let device of deviceList) {
      try {
        if(ios){
          let records = await gatherMeasureFromDevice(device)
          let sent = await send(records)
          newData = [...sent, ...newData]
        } else if (android) {
          while(true){
            // Gather and
            let { records, receivedData } = await gatherMeasuresFromBClassicDeviceAndroid(device)
            if(receivedData.length === 0) {
              break
            }
            numReads+=1
            console.log(`Read number ${numReads}. Received ${numReceived}`)
            
            const dataToDelete = JSON.stringify(cleanupData(receivedData))
            // send and then...
            const bclassic = NativeModules.BluetoothClassicModule
            setNumReceived(numReceived + records.length)
            let sent = await send(records)
            // delete.
            await bclassic.deleteDocuments(dataToDelete)
            // Then show.
            //newData = [...sent, ...newData]
          }
        } else {
          throw new Error('Cannot gather measures from agri. Device is not mobile?')
        }
      } catch (err) {
        if(err === "farmId not determined yet") {
          _numNotConfigured += 1
        }
      } 
    }
    setNumNotConfigured(_numNotConfigured)
    setData([...newData, ...data])
    setFinishedPulling(true)
  }

  const gatherDevices = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoaded(true)
        let deviceList = await props.scanAndListDevices()
        setDeviceList(deviceList)
        resolve(deviceList)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  const loadAsync = async () => {
    try {
      await gatherDevices()
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (isLoaded) {
      if (deviceList.length === 0) {
        setIsLoaded(false) //keep scanning if no devices found
      } else
        gatherAllMeasures()
    }
  }, [deviceList])

  useEffect(() => {
    if (!isLoaded) {
      loadAsync()
    }
  }, [isLoaded])

  const renderItem = ({ item }) => (
    <DataViewCard
      value={{
        ...item,
        type: 'records',
        typeName: indexedMeasures[item.measureTypeId]['measureTypeName']
      }}
      rooms={rooms}
      sections={sections}
    // handleDeleteData={deleteData}
    />
  )

  return (
    <View style={{ alignItems: 'center', padding: 24 }}>
      {!finishedPulling &&
        <View>
          <ActivityIndicator size="small" color="#888888" />
          <Caption>
            {deviceList && deviceList.length > 0 ? "Pulling data, just sit tight." : "Scanning"}
          </Caption>
        </View>
      }
      {deviceList && deviceList.length > 0 &&
        <Text>
          Found {deviceList.length} device{deviceList.length > 1 && "s"}{numReceived > 0 && `, received ${numReceived} measures so far...`}{numNotConfigured > 0 && `, ${numNotConfigured} not configured (ignored).`}
        </Text>
      }
      {data && data.length > 0 &&
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={item => item.id}
          style={{ width: '100%' }}
        />
      }
    </View>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDataPuller)